import React, { useState, useEffect } from 'react'
import { animated, useSpring } from '@react-spring/web'
import ColumnGeneralElement from './LeftColumnSideBarComponents/ColumnGeneralElement'
import ColumnAdvancedElement from './LeftColumnSideBarComponents/ColumnAdvancedElement'
import { BsArrowBarRight } from "react-icons/bs";

function LeftRowColumnBar({ setAttachedImage, attachedImage, allImg, funnelId, setAllImg, fetchImg, sidebarOpen, sections, sectionIdTrack, setSections, setRowchange, setCssEditElemReRender, cssEditElemReRender, setColumnBgElem, columnBgElem, setColumnCss, columnCss, columnHeading, rowIdTrack, setColumnHeading, setEnableColumnBar, enableColumnBar, setColumnName, columnName, columnIdTrack }) {
    // Section Heading control
    const [leftBarElement, setLeftBarElement] = useState(true)
    const [barWidth, setBarWidth] = useState("width:40%")

    const styles = useSpring({
        from: {
            width: "0px",
            position: "relative"
        },
        to: {
            width: "350px",
            position: "relative"
        },
        config: { mass: 2, tension: 3000, friction: 120 },
    })
    const [color, setColor] = useState(columnCss.columnbackgroundcolor)
    const [borderColor, setBorderColor] = useState(columnCss.borderstyleCss.color)

    useEffect(() => {
        var sectionChangeData = sections
        var ColumnStyle = `padding-top:${columnCss.paddingTop}px; padding-bottom:${columnCss.paddingBottom}px; padding-left:${columnCss.paddingLeft}px; padding-right:${columnCss.paddingRight}px; margin-top:${columnCss.marginTop}px; margin-bottom:${columnCss.marginBottom}px; box-shadow:${columnCss.boxShadow}; background-color: ${columnCss.columnbackgroundcolor}; border${columnCss.border}: ${columnCss.borderstyleCss.pixel} ${columnCss.borderstyleCss.type} ${columnCss.borderstyleCss.color}; border-radius:${columnCss.borderRadius};  position: relative; margin-left: auto; margin-right: auto;`
        var mobileColumnStyle = `padding-top:${columnCss.paddingTopMobile}px; padding-bottom:${columnCss.paddingBottomMobile}px; padding-left:${columnCss.paddingLeft}px; padding-right:${columnCss.paddingRight}px; margin-top:${columnCss.marginTopMobile}px; margin-bottom:${columnCss.marginBottomMobile}px; box-shadow:${columnCss.boxShadow}; background-color: ${columnCss.sectionbackgroundcolor}; border${columnCss.border}: ${columnCss.borderstyleCss.pixel} ${columnCss.borderstyleCss.type} ${columnCss.borderstyleCss.color}; border-radius:${columnCss.borderRadius}; `
        sectionChangeData.forEach((element) => {
            if (element.id === sectionIdTrack) {
                element.RowComponent.forEach((element) => {
                    if (element.id === rowIdTrack) {
                        element.RowContainer.forEach((elem) => {
                            if (elem.id === columnIdTrack) {
                                setTimeout(() => {
                                    elem.columnName = columnName;
                                    elem.columnStyle = ColumnStyle;
                                    elem.mobilecolumnstyle = mobileColumnStyle;
                                    elem.columnObject = columnCss;
                                }, 10);
                            }
                        })
                    }
                })
            }
            setColumnHeading(columnName);
            setSections(sectionChangeData);
        });
        setTimeout(() => {
            setRowchange(JSON.stringify(columnCss))
        }, 15);
    }, [sections, columnCss, columnName, color, borderColor])



    function leftBarElementSet(id) {
        if (id === "general") {
            setLeftBarElement(true)
            setBarWidth("width:40%")
        }
        else if (id === "advanced") {
            setLeftBarElement(false)
            setBarWidth("width:80%")
        }
    }
    useEffect(() => {
        setRowchange(color)
    }, [color, rowIdTrack])


    return (
        <>
            {sidebarOpen ? <div id="leftSideNav" className="leftNav customNav custom_scroll bg-light" Style={"width:350px"}>
                <div Style="position: sticky; background-color: #f8f9fa; z-index: 10; top: 0px; width: 100%;">

                    <hr className='m-0' />
                    <nav>
                        <div className="nav nav-tabs justify-content-around" id="nav-tab" role="tablist">
                            <button className="btnTab btnMainTabs " id='leftBarGeneral' data-bs-step="1" data-toggle="tab" href="#nav-general" role="tab" aria-selected="true" onClick={() => { leftBarElementSet("general") }}>General</button>
                            <button className="btnTab btnMainTabs" id='leftBarAdvanced' data-bs-step="4" data-toggle="tab" href="#nav-advanced" role="tab" aria-selected="false" onClick={() => { leftBarElementSet("advanced") }}>Advanced</button>
                            <span onClick={() => { setEnableColumnBar(false) }} Style='font-size: 25px; cursor:pointer; padding-top: 8px;'><BsArrowBarRight /></span>
                            <div className="progress bg-transparent" Style="height: 3px;position: absolute;width: 100%;bottom: 0;">
                                <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="5" Style={barWidth}></div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="tab-content" id="nav-tabContent" Style="box-shadow:none">
                    {cssEditElemReRender ? leftBarElement ? <ColumnGeneralElement setAttachedImage={setAttachedImage} attachedImage={attachedImage} allImg={allImg} funnelId={funnelId} setAllImg={setAllImg} fetchImg={fetchImg} setColumnCss={setColumnCss} columnCss={columnCss} setColumnName={setColumnName} columnName={columnName} setColor={setColor} color={color} setcolumnBgElem={setColumnBgElem} columnBgElem={columnBgElem} setEnableColumnBar={setEnableColumnBar} setRowchange={setRowchange} /> : <ColumnAdvancedElement setAttachedImage={setAttachedImage} attachedImage={attachedImage} allImg={allImg} funnelId={funnelId} setAllImg={setAllImg} fetchImg={fetchImg} setColumnCss={setColumnCss} columnCss={columnCss} setRowchange={setRowchange} setBorderColor={setBorderColor} borderColor={borderColor} columnIdTrack={columnIdTrack} /> : ""}

                </div>
            </div> : <animated.div id="leftSideNav" className="leftNav customNav custom_scroll bg-light" style={styles}>
                <div Style="position: sticky; background-color: #f8f9fa; z-index: 10; top: 0px; width: 100%;">
                    <hr className='m-0' />
                    <nav>
                        <div className="nav nav-tabs justify-content-around" id="nav-tab" role="tablist">
                            <button className="btnTab btnMainTabs " id='leftBarGeneral' data-bs-step="1" data-toggle="tab" href="#nav-general" role="tab" aria-selected="true" onClick={() => { leftBarElementSet("general") }}>General</button>
                            <button className="btnTab btnMainTabs" id='leftBarAdvanced' data-bs-step="4" data-toggle="tab" href="#nav-advanced" role="tab" aria-selected="false" onClick={() => { leftBarElementSet("advanced") }}>Advanced</button>
                            <span onClick={() => { setEnableColumnBar(false) }} Style='font-size: 25px; cursor:pointer; padding-top: 8px;'><BsArrowBarRight /></span>
                            <div className="progress bg-transparent" Style="height: 3px;position: absolute;width: 100%;bottom: 0;">
                                <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="5" Style={barWidth}></div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="tab-content" id="nav-tabContent" Style="box-shadow:none">
                    {cssEditElemReRender ? leftBarElement ? <ColumnGeneralElement setAttachedImage={setAttachedImage} attachedImage={attachedImage} allImg={allImg} funnelId={funnelId} setAllImg={setAllImg} fetchImg={fetchImg} setColumnCss={setColumnCss} columnCss={columnCss} setColumnName={setColumnName} columnName={columnName} setColor={setColor} color={color} setcolumnBgElem={setColumnBgElem} columnBgElem={columnBgElem} setEnableColumnBar={setEnableColumnBar} setRowchange={setRowchange} /> : <ColumnAdvancedElement setAttachedImage={setAttachedImage} attachedImage={attachedImage} allImg={allImg} funnelId={funnelId} setAllImg={setAllImg} fetchImg={fetchImg} setColumnCss={setColumnCss} columnCss={columnCss} setRowchange={setRowchange} setBorderColor={setBorderColor} borderColor={borderColor} columnIdTrack={columnIdTrack} /> : ""}

                </div>
            </animated.div>}
        </>
    )
}

export default LeftRowColumnBar