import React, { useState, useEffect } from 'react'
import { animated, useSpring } from '@react-spring/web'
import RowGeneralElement from './LeftRowSideBarComponents/RowGeneralElement'
import RowAdvancedElement from './LeftRowSideBarComponents/RowAdvancedElement'
import { BsArrowBarRight } from "react-icons/bs";

function LeftRowSideBar({setAttachedImage, attachedImage, sidebarOpen, sections, funnelId, allImg, setAllImg, fetchImg, sectionIdTrack, setSections, setRowchange, setCssEditElemReRender, cssEditElemReRender, setRowBgElem, rowBgElem, setRowCss, rowCss, rowHeading, rowIdTrack, setRowHeading, setEnableRowSection, enableRowSection, setRowName, rowName }) {
    // Section Heading control
    const [leftBarElement, setLeftBarElement] = useState(true)
    const [barWidth, setBarWidth] = useState("width:40%")

    const styles = useSpring({
        from: {
            width: "0px",
            position: "relative"
        },
        to: {
            width: "350px",
            position: "relative"
        },
        config: { mass: 2, tension: 3000, friction: 120 },
    })
    const [color, setColor] = useState(rowCss.rowbackgroundcolor)
    const [borderColor, setBorderColor] = useState(rowCss.borderstyleCss.color)

    useEffect(() => {
        var sectionChangeData = sections
        var RowStyle = `padding-top:${rowCss.paddingTop}px; padding-bottom:${rowCss.paddingBottom}px; padding-left:${rowCss.paddingLeft}px; padding-right:${rowCss.paddingRight}px; margin-top:${rowCss.marginTop}px; margin-bottom:${rowCss.marginBottom}px; box-shadow:${rowCss.boxShadow}; background-color: ${rowCss.rowbackgroundcolor}; border${rowCss.border}: ${rowCss.borderstyleCss.pixel} ${rowCss.borderstyleCss.type} ${rowCss.borderstyleCss.color}; border-radius:${rowCss.borderRadius}; width:${rowCss.desktoprowwidth}%; position: relative; margin-left: auto; margin-right: auto;`
        var mobileRowStyle = `padding-top:${rowCss.paddingTopMobile}px; padding-bottom:${rowCss.paddingBottomMobile}px; padding-left:${rowCss.paddingLeft}px; padding-right:${rowCss.paddingRight}px; margin-top:${rowCss.marginTopMobile}px; margin-bottom:${rowCss.marginBottomMobile}px; box-shadow:${rowCss.boxShadow}; background-color: ${rowCss.sectionbackgroundcolor}; border${rowCss.border}: ${rowCss.borderstyleCss.pixel} ${rowCss.borderstyleCss.type} ${rowCss.borderstyleCss.color}; border-radius:${rowCss.borderRadius}; position:relative`
        sectionChangeData.forEach((element) => {
            if (element.id === sectionIdTrack) {
                element.RowComponent.forEach((element) => {
                    if (element.id === rowIdTrack) {
                        element.rowName = rowName;
                        element.rowstyle = RowStyle;
                        element.mobilerowstyle = mobileRowStyle;
                        element.rowObject = rowCss;
                    }
                })
            }
            setRowHeading(rowName);
            setSections(sectionChangeData);
        });
        setRowchange(rowCss)
    }, [sections, rowCss, rowName])

    function leftBarElementSet(id) {
        if (id === "general") {
            setLeftBarElement(true)
            setBarWidth("width:40%")
        }
        else if (id === "advanced") {
            setLeftBarElement(false)
            setBarWidth("width:80%")
        }
    }
    useEffect(() => {
        setRowchange(color)
    }, [color, rowIdTrack])

    return (
        <>
            {sidebarOpen ? <div id="leftSideNav" className="leftNav customNav custom_scroll bg-light" Style='width: 350px'>
                <div Style="position: sticky; background-color: #f8f9fa; z-index: 10; top: 0px; width: 100%;">

                    <hr className='m-0' />
                    <nav>
                        <div className="nav nav-tabs justify-content-around" id="nav-tab" role="tablist">
                            <button className="btnTab btnMainTabs " id='leftBarGeneral' data-bs-step="1" data-toggle="tab" href="#nav-general" role="tab" aria-selected="true" onClick={() => { leftBarElementSet("general") }}>General</button>
                            <button className="btnTab btnMainTabs" id='leftBarAdvanced' data-bs-step="4" data-toggle="tab" href="#nav-advanced" role="tab" aria-selected="false" onClick={() => { leftBarElementSet("advanced") }}>Advanced</button>
                            <span onClick={() => { setEnableRowSection(false) }} Style='font-size: 25px; cursor:pointer; padding-top: 8px;'><BsArrowBarRight /></span>
                            <div className="progress bg-transparent" Style="height: 3px;position: absolute;width: 100%;bottom: 0;">
                                <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="5" Style={barWidth}></div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="tab-content" id="nav-tabContent" Style="box-shadow:none">
                    {cssEditElemReRender ? leftBarElement ? <RowGeneralElement setAttachedImage={setAttachedImage} attachedImage={attachedImage} allImg={allImg} funnelId={funnelId} setAllImg={setAllImg} fetchImg={fetchImg} setRowCss={setRowCss} rowCss={rowCss} setRowName={setRowName} rowName={rowName} setColor={setColor} color={color} setRowBgElem={setRowBgElem} rowBgElem={rowBgElem} setEnableRowSection={setEnableRowSection} setRowchange={setRowchange} /> : <RowAdvancedElement setAttachedImage={setAttachedImage} attachedImage={attachedImage} allImg={allImg} funnelId={funnelId} setAllImg={setAllImg} fetchImg={fetchImg} setRowCss={setRowCss} rowCss={rowCss} setRowchange={setRowchange} setBorderColor={setBorderColor} borderColor={borderColor} rowIdTrack={rowIdTrack} /> : ""}
                </div>
            </div> : <animated.div id="leftSideNav" className="leftNav customNav custom_scroll bg-light" style={styles}>
                <div Style="position: sticky; background-color: #f8f9fa; z-index: 10; top: 0px; width: 100%;">

                    <hr className='m-0' />
                    <nav>
                        <div className="nav nav-tabs justify-content-around" id="nav-tab" role="tablist">
                            <button className="btnTab btnMainTabs " id='leftBarGeneral' data-bs-step="1" data-toggle="tab" href="#nav-general" role="tab" aria-selected="true" onClick={() => { leftBarElementSet("general") }}>General</button>
                            <button className="btnTab btnMainTabs" id='leftBarAdvanced' data-bs-step="4" data-toggle="tab" href="#nav-advanced" role="tab" aria-selected="false" onClick={() => { leftBarElementSet("advanced") }}>Advanced</button>
                            <span onClick={() => { setEnableRowSection(false) }} Style='font-size: 25px; cursor:pointer; padding-top: 8px;'><BsArrowBarRight /></span>
                            <div className="progress bg-transparent" Style="height: 3px;position: absolute;width: 100%;bottom: 0;">
                                <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="5" Style={barWidth}></div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="tab-content" id="nav-tabContent" Style="box-shadow:none">
                    {cssEditElemReRender ? leftBarElement ? <RowGeneralElement setAttachedImage={setAttachedImage} attachedImage={attachedImage} allImg={allImg} funnelId={funnelId} setAllImg={setAllImg} fetchImg={fetchImg} setRowCss={setRowCss} rowCss={rowCss} setRowName={setRowName} rowName={rowName} setColor={setColor} color={color} setRowBgElem={setRowBgElem} rowBgElem={rowBgElem} setEnableRowSection={setEnableRowSection} setRowchange={setRowchange} /> : <RowAdvancedElement setAttachedImage={setAttachedImage} attachedImage={attachedImage} allImg={allImg} funnelId={funnelId} setAllImg={setAllImg} fetchImg={fetchImg} setRowCss={setRowCss} rowCss={rowCss} setRowchange={setRowchange} setBorderColor={setBorderColor} borderColor={borderColor} rowIdTrack={rowIdTrack} /> : ""}
                    {/*  */}
                </div>
            </animated.div>}
        </>
    )
}

export default LeftRowSideBar