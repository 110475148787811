import React, { useEffect, useState } from 'react'
import BorderColorPicker from '../BorderColorPicker';
import img from '../../static/images/backgroundfreeimage.png'
import ShapeColor from '../ShapeColor';
import { Slider, TextField, Select, InputLabel, MenuItem, FormControl, Button } from "@mui/material";

function ElementAdvancedBar({ setElementCss, elementCss, setRowchange, setBorderColor, borderColor, activeElement, elementType }) {
    const [borderRadiusType, setBorderRadiusType] = useState(elementCss.borderRadiusType)
    const [borderRadiusData, setBorderRadiusData] = useState(elementCss.borderRadiusData)
    const [borderRadius, setBorderRadius] = useState()
    const [enableBorderColor, setEnableBorderColor] = useState(false)
    const [btnBorderRadiusType, setBtnBorderRadiusType] = useState(elementCss.btnborderRadiusType)
    const [btnBorderRadiusData, setBtnBorderRadiusData] = useState(elementCss.btnborderRadiusData)
    const [btnBorderRadius, setBtnBorderRadius] = useState()
    const [btnEnableBorderColor, setbtnEnableBorderColor] = useState(false)
    const [imageBorderRadiusType, setImageBorderRadiusType] = useState(elementCss.imageRadiusType)
    const [imageBorderRadiusData, setImageBorderRadiusData] = useState(elementCss.imageRadius)
    const [imageBorderRadius, setImageBorderRadius] = useState()
    // const [enableShapeColor, setEnableShapeColor] = useState(false)
    // const [shapeWidth,setShapeWidth]=useState()
    // const [shapeHeight,setShapeHeight]=useState()
    useEffect(() => {
        if (borderRadiusType === 'AllBorderEdges') {
            setBorderRadius(borderRadiusData)
        }
        else if (borderRadiusType === 'TopBorderEdges') {
            setBorderRadius(borderRadiusData + ' ' + borderRadiusData + ' ' + '0px' + ' ' + '0px')
        }
        else if (borderRadiusType === 'BottomBorderEdges') {
            setBorderRadius('0px' + ' ' + '0px' + ' ' + borderRadiusData + ' ' + borderRadiusData)
        }
        let spacecssData = elementCss
        spacecssData.borderRadiusData = borderRadiusData
        spacecssData.borderRadiusType = borderRadiusType
        setElementCss(spacecssData)
    }, [borderRadiusType, borderRadiusData])

    useEffect(() => {
        var data = elementCss
        data.borderRadius = borderRadius
        setElementCss(data)
        setRowchange(borderRadius)
    }, [borderRadius])

    useEffect(() => {
        if (btnBorderRadiusType === 'AllBorderEdges') {
            setBtnBorderRadius(btnBorderRadiusData)
        }
        else if (btnBorderRadiusType === 'TopBorderEdges') {
            setBtnBorderRadius(btnBorderRadiusData + ' ' + btnBorderRadiusData + ' ' + '0px' + ' ' + '0px')
        }
        else if (btnBorderRadiusType === 'BottomBorderEdges') {
            setBtnBorderRadius('0px' + ' ' + '0px' + ' ' + btnBorderRadiusData + ' ' + btnBorderRadiusData)
        }
        let spacecssData = elementCss
        spacecssData.btnborderRadiusData = btnBorderRadiusData
        spacecssData.btnborderRadiusType = btnBorderRadiusType
        setElementCss(spacecssData)
    }, [btnBorderRadiusType, btnBorderRadiusData])

    useEffect(() => {
        var data = elementCss
        data.btnborderRadius = btnBorderRadius
        setElementCss(data)
        setRowchange(btnBorderRadius)
    }, [btnBorderRadius])

    useEffect(() => {
        if (imageBorderRadiusType === 'AllBorderEdges') {
            setImageBorderRadius(imageBorderRadiusData)
        }
        else if (imageBorderRadiusType === 'TopBorderEdges') {
            setImageBorderRadius(imageBorderRadiusData + ' ' + imageBorderRadiusData + ' ' + '0px' + ' ' + '0px')
        }
        else if (imageBorderRadiusType === 'BottomBorderEdges') {
            setImageBorderRadius('0px' + ' ' + '0px' + ' ' + imageBorderRadiusData + ' ' + imageBorderRadiusData)
        }
        let spacecssData = elementCss
        spacecssData.imageRadius = imageBorderRadiusData
        spacecssData.imageRadiusType = imageBorderRadiusType
        setElementCss(spacecssData)
        setRowchange(spacecssData.imageRadius)
    }, [imageBorderRadiusType, imageBorderRadiusData])

    useEffect(() => {
        var data = elementCss
        data.imageRadius = imageBorderRadius
        setElementCss(data)
        setRowchange(imageBorderRadius)
    }, [imageBorderRadius])

    // Copy Section Id to clipboard
    function copySectionId() {
        var copyText = document.getElementById("rowIdElem");
        navigator.clipboard.writeText(copyText.innerText);
    }
    function setBorderStyle(data, type) {
        var sectiondcss = elementCss
        if (type === 'type') {
            sectiondcss.borderstyleCss.type = data
            setElementCss(sectiondcss)
            setRowchange(elementCss.borderstyleCss)
        }
        else if (type === 'pixel') {
            sectiondcss.borderstyleCss.pixel = data
            setElementCss(sectiondcss)
            setRowchange(elementCss.borderstyleCss)
        }

    }
    function setBtnBorderStyle(data, type) {
        var sectiondcss = elementCss
        if (type === 'type') {
            sectiondcss.btnborderstyleCss.type = data
            setElementCss(sectiondcss)
            setRowchange(elementCss.borderstyleCss)
        }
        else if (type === 'pixel') {
            sectiondcss.btnborderstyleCss.pixel = data
            setElementCss(sectiondcss)
            setRowchange(elementCss.borderstyleCss)
        }

    }
    var openButton;
    if (elementCss.borderstyleCss.color === 'rgba(255, 255, 255, 0)') {
        openButton = `background-image: url(${img})`
    }
    else {
        openButton = `background-color: ${borderColor} !important`
    }
    var openBtnButton;
    if (elementCss.borderstyleCss.color === 'rgba(255, 255, 255, 0)') {
        openBtnButton = `background-image: url(${img})`
    }
    else {
        openBtnButton = `background-color: ${elementCss.btnborderstyleCss.color} !important`
    }

    function copySectionId() {
        var copyText = document.getElementById("rowIdElem");
        navigator.clipboard.writeText(copyText.innerText);
        document.getElementById('rowCopytext').classList.add("active");
        setTimeout(function () {
            document.getElementById('rowCopytext').classList.remove("active");
        }, 2500);
    }
    return (
        <div className="" id="nav-advanced" role="tabpanel">
            <div id="AdvancedProperties" Style="padding-top: 10px;text-align: start;">
                <div className="container" Style="padding: 0px 10px 0px 10px;">
                    <div className="SectionPropsWrapper mb-3 mt-3 pt-3" Style="padding-bottom: 30px">
                        {elementType === 'image' || elementType === 'imagepopup' || elementType === 'videopopup' || elementType === 'imagefeature' ? "" : <>
                            <div className="PropsTitle">Border Options</div>
                            <div Style="margin-top: 10px; background-color: rgb(255, 255, 255); padding: 0px 10px;" >
                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="border-Sticky">Border</InputLabel>
                                        <Select
                                            labelId="border"
                                            id="border"
                                            value={elementCss.border}
                                            name='border'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="border"
                                        >
                                            <MenuItem value={"noBorder"}>No Border</MenuItem>
                                            <MenuItem value={" "}>Full Border</MenuItem>
                                            <MenuItem value={"-top"}>Top Border</MenuItem>
                                            <MenuItem value={"-bottom"}>Bottom Border</MenuItem>
                                            <MenuItem value={"-block"}>Top and Bottom Border</MenuItem>
                                            <MenuItem value={"-left"}>Left Border</MenuItem>
                                            <MenuItem value={"-right"}>Right Border</MenuItem>
                                            <MenuItem value={"-inline"}>Left and Right Border</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div id="BorderOptions" Style={`display:${elementCss.border === 'noBorder' ? 'none' : 'block'}`}>
                                    <div className='d-flex'>
                                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                            <InputLabel id="type-Sticky">Border Style</InputLabel>
                                            <Select
                                                labelId="type"
                                                id="type"
                                                value={elementCss.borderstyleCss.type}
                                                name='type'
                                                onChange={(e) => { setBorderStyle(e.target.value, 'type') }}
                                                label="type"
                                            >
                                                <MenuItem value={"solid"}>Solid</MenuItem>
                                                <MenuItem value={"dashed"}>Dashed</MenuItem>
                                                <MenuItem value={"dotted"}>Dotted</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='d-flex'>
                                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                            <InputLabel id="pixel-Sticky">Border Width</InputLabel>
                                            <Select
                                                labelId="pixel"
                                                id="pixel"
                                                value={elementCss.borderstyleCss.pixel}
                                                name='pixel'
                                                onChange={(e) => { setBorderStyle(e.target.value, 'pixel') }}
                                                label="pixel"
                                            >
                                                <MenuItem value={"1px"}>1px</MenuItem>
                                                <MenuItem value={"2px"}>2px</MenuItem>
                                                <MenuItem value={"3px"}>3px</MenuItem>
                                                <MenuItem value={"5px"}>5px</MenuItem>
                                                <MenuItem value={"10px"}>10px</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="border-picker-wrapper all-picker-wrappers" Style="padding:0px;">
                                        <div Style='display:flex;justify-content: space-between;padding: 0px 30px;margin-bottom: 15px;'>
                                            <span>Background Color:</span>
                                            <div className="color-picker-button float-right" Style={`${openButton};border-radius: 15px;`} onClick={() => { setEnableBorderColor(!enableBorderColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                                        </div>
                                        {/* <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                                            <button className="color-picker-button btn float-right" Style={`${openButton}; border-radius: 15px; font-weight:900`} onClick={() => { setEnableBorderColor(!enableBorderColor) }}>Background Color:</button>
                                        </div> */}
                                        {enableBorderColor ? <BorderColorPicker Css={elementCss} setCss={setElementCss} setRowchange={setRowchange} setEnableBorderColor={setEnableBorderColor} setBorderColor={setBorderColor} type='row' /> : ''}
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="borderRadiusData-Sticky">Border Radius</InputLabel>
                                        <Select
                                            labelId="borderRadiusData"
                                            id="borderRadiusData"
                                            value={borderRadiusData}
                                            name='borderRadiusData'
                                            onChange={(e) => { setBorderRadiusData(e.target.value) }}
                                            label="borderRadiusData"
                                        >
                                            <MenuItem value={"0px"}>0px</MenuItem>
                                            <MenuItem value={"5px"}>5px</MenuItem>
                                            <MenuItem value={"10px"}>10px</MenuItem>
                                            <MenuItem value={"15px"}>15px</MenuItem>
                                            <MenuItem value={"20px"}>20px</MenuItem>
                                            <MenuItem value={"25px"}>25px</MenuItem>
                                            <MenuItem value={"50px"}>50px</MenuItem>
                                            <MenuItem value={"75px"}>75px</MenuItem>
                                            <MenuItem value={"100px"}>100px</MenuItem>
                                            <MenuItem value={"125px"}>125px</MenuItem>
                                            <MenuItem value={"150px"}>150px</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="borderRadiusType-Sticky">Radius Edge</InputLabel>
                                        <Select
                                            labelId="borderRadiusType"
                                            id="borderRadiusType"
                                            value={borderRadiusType}
                                            name='borderRadiusType'
                                            onChange={(e) => { setBorderRadiusType(e.target.value) }}
                                            label="borderRadiusType"
                                        >
                                            <MenuItem value={"AllBorderEdges"}>All Edges</MenuItem>
                                            <MenuItem value={"TopBorderEdges"}>Top Only Edges</MenuItem>
                                            <MenuItem value={"BottomBorderEdges"}>Bottom Only Edges</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </>}

                        {elementType === 'image' || elementType === 'imagepopup' || elementType === 'videopopup' || elementType === 'imagefeature' ? <div className="container">
                            <div className='d-flex'>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="imageRadius-Sticky">Image Radius</InputLabel>
                                    <Select
                                        labelId="imageRadius"
                                        id="imageRadius"
                                        value={elementCss.imageRadius}
                                        name='imageRadius'
                                        onChange={(e) => { setImageBorderRadiusData(e.target.value) }}
                                        label="imageRadius"
                                    >
                                        <MenuItem value={"0px"}>0px</MenuItem>
                                        <MenuItem value={"5px"}>5px</MenuItem>
                                        <MenuItem value={"10px"}>10px</MenuItem>
                                        <MenuItem value={"15px"}>15px</MenuItem>
                                        <MenuItem value={"20px"}>20px</MenuItem>
                                        <MenuItem value={"25px"}>25px</MenuItem>
                                        <MenuItem value={"50px"}>50px</MenuItem>
                                        <MenuItem value={"75px"}>75px</MenuItem>
                                        <MenuItem value={"100px"}>100px</MenuItem>
                                        <MenuItem value={"125px"}>125px</MenuItem>
                                        <MenuItem value={"150px"}>150px</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='d-flex'>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="imageRadiusType-Sticky">Radius Edge</InputLabel>
                                    <Select
                                        labelId="imageRadiusType"
                                        id="imageRadiusType"
                                        value={elementCss.imageRadiusType}
                                        name='imageRadiusType'
                                        onChange={(e) => { setImageBorderRadiusType(e.target.value) }}
                                        label="imageRadiusType"
                                    >
                                        <MenuItem value={"AllBorderEdges"}>All Edges</MenuItem>
                                        <MenuItem value={"TopBorderEdges"}>Top Only Edges</MenuItem>
                                        <MenuItem value={"BottomBorderEdges"}>Bottom Only Edges</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            {/* <span>Image Border</span>
                            <select className="form-control ImageElmBorderSelect mb-3" name="imageBoxBorder" onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                <option value="0px none rgb(33, 37, 41)">None</option>
                                <option value="3px solid rgb(255, 255, 255)">White Thumbnail Border</option>
                                <option value="3px solid rgba(0, 0, 0, 0.7)">Dark Thumbnail Border</option>
                                <option value="1px solid rgba(0, 0, 0, 0.15)">Transparent Thumbnail Border</option>
                            </select> */}
                            <div className='d-flex'>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="imageBoxBorder-Sticky">Image Border</InputLabel>
                                    <Select
                                        labelId="imageBoxBorder"
                                        id="imageBoxBorder"
                                        value={elementCss.imageBoxBorder}
                                        name='imageBoxBorder'
                                        onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                        label="imageBoxBorder"
                                    >
                                        <MenuItem value={"0px none rgb(33, 37, 41)"}>None</MenuItem>
                                        <MenuItem value={"3px solid rgb(255, 255, 255)"}>White Thumbnail Border</MenuItem>
                                        <MenuItem value={"3px solid rgba(0, 0, 0, 0.7)"}>Dark Thumbnail Border</MenuItem>
                                        <MenuItem value={"1px solid rgba(0, 0, 0, 0.15)"}>Transparent Thumbnail Border</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {/* <span>Image Shadow</span>
                            <select className="form-control ImageElmShadowSelect mb-3" name="imageBoxShadow" onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                <option value="none">None</option>
                                <option value="rgba(0, 0, 0, 0.2) 0px 1px 5px">Light Shadow</option>
                                <option value="rgba(0, 0, 0, 0.3) 0px 2px 5px 2px">Full Shadow</option>
                                <option value="rgba(0, 0, 0, 0.4) 0px 2px 5px 2px">Dark Shadow</option>
                            </select> */}
                            <div className='d-flex'>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="imageBoxShadow-Sticky">Image Shadow</InputLabel>
                                    <Select
                                        labelId="imageBoxShadow"
                                        id="imageBoxShadow"
                                        value={elementCss.imageBoxShadow}
                                        name='imageBoxShadow'
                                        onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                        label="imageBoxShadow"
                                    >
                                        <MenuItem value={"none"}>None</MenuItem>
                                        <MenuItem value={"rgba(0, 0, 0, 0.2) 0px 1px 5px"}>Light Shadow</MenuItem>
                                        <MenuItem value={"rgba(0, 0, 0, 0.3) 0px 2px 5px 2px"}>Full Shadow</MenuItem>
                                        <MenuItem value={"rgba(0, 0, 0, 0.4) 0px 2px 5px 2px"}>Dark Shadow</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {/* <span>Image Effects</span>
                            <select className="form-control ImageElmEffectsSelect mb-3" name="imageEditing" onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                <option value="none">Full Color</option>
                                <option value="grayscale(100%)">Black &amp; White</option>
                                <option value="blur(1px)">Blur</option>
                                <option value="brightness(0.5)">Fade</option>
                                <option value="contrast(0.5)">Contrast</option>
                                <option value="drop-shadow(2px 4px 6px black)">Drop-shadow</option>
                                <option value="hue-rotate(45deg)">Hue-rotate</option>
                                <option value="invert(1)">Invert</option>
                                <option value="opacity(0.5)">Opacity</option>
                                <option value="revert">Revert</option>
                                <option value="saturate(0.5)">Saturate</option>
                                <option value="sepia(1)">Sepia</option>
                            </select> */}
                            <div className='d-flex'>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="imageEditing-Sticky">Image Effects</InputLabel>
                                    <Select
                                        labelId="imageEditing"
                                        id="imageEditing"
                                        value={elementCss.imageEditing}
                                        name='imageEditing'
                                        onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                        label="imageEditing"
                                    >
                                        <MenuItem value={"none"}>Full Color</MenuItem>
                                        <MenuItem value={"grayscale(100%)"}>Black &amp; White</MenuItem>
                                        <MenuItem value={"blur(1px)"}>Blur</MenuItem>
                                        <MenuItem value={"brightness(0.5)"}>Fade</MenuItem>
                                        <MenuItem value={"contrast(0.5)"}>Contrast</MenuItem>
                                        <MenuItem value={"drop-shadow(2px 4px 6px black)"}>Drop-shadow</MenuItem>
                                        <MenuItem value={"hue-rotate(45deg)"}>Hue-rotate</MenuItem>
                                        <MenuItem value={"invert(1)"}>Invert</MenuItem>
                                        <MenuItem value={"opacity(0.5)"}>Opacity</MenuItem>
                                        <MenuItem value={"saturate(0.5)"}>Saturate</MenuItem>
                                        <MenuItem value={"sepia(1)"}>Sepia</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {/* <span>Image Animations</span>
                            <select className="form-control ImageAnimationSelect mb-3" name='imageAnimation' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                <option value="none">No Animation</option>
                                <option value="pulse">Pulse (loop)</option>
                                <option value="buttonRocking">Rocking (loop)</option>
                                <option value="buttonBounce">Bounce (loop)</option>
                                <option value="bounceHero">BounceHero 3 (loop)</option>
                                <option value="buttonElevate">Elevate (hover)</option>
                                <option value="buttonWobble">Woobble (hover)</option>
                                <option value="grow">Grow (hover)</option>
                                <option value="growsmall">Shrink (hover)</option>
                                <option value="imgswitch">Image Switch (hover)</option>
                            </select> */}
                            <div className='d-flex'>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="imageAnimation-Sticky">Image Animations</InputLabel>
                                    <Select
                                        labelId="imageAnimation"
                                        id="imageAnimation"
                                        value={elementCss.imageAnimation}
                                        name='imageAnimation'
                                        onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                        label="imageAnimation"
                                    >
                                        <MenuItem value={"none"}>No Animation</MenuItem>
                                        <MenuItem value={"pulse"}>Pulse (loop)</MenuItem>
                                        <MenuItem value={"buttonRocking"}>Rocking (loop)</MenuItem>
                                        <MenuItem value={"buttonBounce"}>Bounce (loop)</MenuItem>
                                        <MenuItem value={"bounceHero"}>BounceHero 3 (loop)</MenuItem>
                                        <MenuItem value={"buttonElevate"}>Elevate (hover)</MenuItem>
                                        <MenuItem value={"buttonWobble"}>Woobble (hover)</MenuItem>
                                        <MenuItem value={"grow"}>Grow (hover)</MenuItem>
                                        <MenuItem value={"growsmall"}>Shrink (hover)</MenuItem>
                                        <MenuItem value={"imgswitch"}>Image Switch (hover)</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div Style="display: none" id="hoverImg">
                                <span>Hover Image</span>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control hoverImageElmInput" placeholder="Image URL" controlling="Imagec0e5b" />
                                    <div className="input-group-append">
                                        <button id="imgSwitchSelect" className="btn btn-outline-secondary" type="button" data-toggle="modal" data-target="#ImageModal"><i className="fa fa-image"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div id="imageAnimSpeed" Style="display: none;">
                                <span>Image Animation Speed</span>
                                <select className="form-control ImageAnimationSpeedSelect mb-3" controlling="Imagec0e5b">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>

                            {/* <span>Image Mask</span>
                            <select className="form-control maskSelect mb-3" id='imageMask' name='imageMask' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                <option value="none">None</option>
                                <option value="speechmask">Speech Bubble</option>
                                <option value="scribblemask">Scribble</option>
                                <option value="glass">Broken Glass</option>
                                <option value="streak">Streak</option>
                                <option value="splash">Splash</option>
                                <option value="blackhole">Circle</option>
                                <option value="mountain">Mountains</option>
                            </select> */}

                            <div className='d-flex'>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="imageMask-Sticky">Image Mask</InputLabel>
                                    <Select
                                        labelId="imageMask"
                                        id="imageMask"
                                        value={elementCss.imageMask}
                                        name='imageMask'
                                        onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                        label="imageMask"
                                    >
                                        <MenuItem value={"none"}>None</MenuItem>
                                        <MenuItem value={"speechmask"}>Speech Bubble</MenuItem>
                                        <MenuItem value={"scribblemask"}>Scribble</MenuItem>
                                        <MenuItem value={"glass"}>Broken Glass</MenuItem>
                                        <MenuItem value={"streak"}>Streak</MenuItem>
                                        <MenuItem value={"splash"}>Splash</MenuItem>
                                        <MenuItem value={"blackhole"}>Circle</MenuItem>
                                        <MenuItem value={"mountain"}>Mountains</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div> : ''}
                    </div>
                    {elementType === 'button' ? <>
                        <div className="SectionPropsWrapper mb-3 mt-3 pt-3" Style="padding-bottom: 30px">
                            <div className="PropsTitle">Button Border Options</div>
                            <div Style="margin-top: 10px; background-color: rgb(255, 255, 255); padding: 0px 10px;">
                                {/* <span>Border</span>
                                <select className="form-control BorderSelect mb-3" id='btncolumnborderType' name="btnborder" onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                    <option value="noBorder">No Border</option>
                                    <option value="">Full Border</option>
                                    <option value="-top">Top Border</option>
                                    <option value="-bottom">Bottom Border</option>
                                    <option value="-block">Top and Bottom Border</option>
                                    <option value="-left">Left Border</option>
                                    <option value="-right ">Right Border</option>
                                    <option value="-inline">Left and Right Border</option>
                                </select> */}
                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="btnborder-Sticky">Button Border</InputLabel>
                                        <Select
                                            labelId="btnborder"
                                            id="btnborder"
                                            value={elementCss.btnborder}
                                            name='btnborder'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="btnborder"
                                        >
                                            <MenuItem value={"noBorder"}>No Border</MenuItem>
                                            <MenuItem value={" "}>Full Border</MenuItem>
                                            <MenuItem value={"-top"}>Top Border</MenuItem>
                                            <MenuItem value={"-bottom"}>Bottom Border</MenuItem>
                                            <MenuItem value={"-block"}>Top and Bottom Border</MenuItem>
                                            <MenuItem value={"-left"}>Left Border</MenuItem>
                                            <MenuItem value={"-right"}>Right Border</MenuItem>
                                            <MenuItem value={"-inline"}>Left and Right Border</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div id="BorderOptions" Style={`display:${elementCss.btnborder === 'noBorder' ? 'none' : 'block'}`}>
                                    <div className='d-flex'>
                                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                            <InputLabel id="btntype-Sticky">Border Style</InputLabel>
                                            <Select
                                                labelId="btntype"
                                                id="btntype"
                                                value={elementCss.btnborderstyleCss.type}
                                                name='btntype'
                                                onChange={(e) => { setBtnBorderStyle(e.target.value, 'type') }}
                                                label="btntype"
                                            >
                                                <MenuItem value={"solid"}>Solid</MenuItem>
                                                <MenuItem value={"dashed"}>Dashed</MenuItem>
                                                <MenuItem value={"dotted"}>Dotted</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='d-flex'>
                                        <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                            <InputLabel id="btnpixel-Sticky">Border Width</InputLabel>
                                            <Select
                                                labelId="btnpixel"
                                                id="btnpixel"
                                                value={elementCss.btnborderstyleCss.pixel}
                                                name='btnpixel'
                                                onChange={(e) => { setBtnBorderStyle(e.target.value, 'pixel') }}
                                                label="btnpixel"
                                            >
                                                <MenuItem value={"1px"}>1px</MenuItem>
                                                <MenuItem value={"2px"}>2px</MenuItem>
                                                <MenuItem value={"3px"}>3px</MenuItem>
                                                <MenuItem value={"5px"}>5px</MenuItem>
                                                <MenuItem value={"10px"}>10px</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="border-picker-wrapper all-picker-wrappers" Style="padding:0px;">
                                        <div Style='display:flex;justify-content: center;padding: 0px 30px;margin-bottom: 15px;'>
                                            <button className="color-picker-button btn float-right" Style={`${openBtnButton}; border-radius: 15px; font-weight:900`} onClick={() => { setbtnEnableBorderColor(!btnEnableBorderColor) }}>Background Color:</button>
                                        </div>
                                        {btnEnableBorderColor ? <BorderColorPicker Css={elementCss} setCss={setElementCss} setRowchange={setRowchange} setEnableBorderColor={setbtnEnableBorderColor} type='btn' /> : ''}                                    </div>
                                    {/* <span>Border Style</span>
                                    <select className="form-control BorderStyleSelect mb-3" id='btncolumnborderStyleType' name='btntype' onClick={(e) => { setBtnBorderStyle(e.target.value, 'type') }}>
                                        <option value="solid">Solid</option>
                                        <option value="dashed">Dashed</option>
                                        <option value="dotted">Dotted</option>
                                    </select>
                                    <span>Border Width</span>
                                    <select className="form-control BorderWidthSelect mb-3" id='btncolumnborderStylePixel' name='btnpixel' onClick={(e) => { setBtnBorderStyle(e.target.value, 'pixel') }}>
                                        <option value="1px">1px</option>
                                        <option value="2px">2px</option>
                                        <option value="3px">3px</option>
                                        <option value="5px">5px</option>
                                        <option value="10px">10px</option>
                                    </select> */}

                                    {/* <div className="border-picker-wrapper all-picker-wrappers" Style="padding:0px;">
                                        <div Style='display:flex;justify-content:center'>
                                            <span>Background Color:</span>
                                            <div className="color-picker-button float-right" Style={`${openBtnButton};margin-left: 5rem;border-radius: 15px;`} onClick={() => { setbtnEnableBorderColor(!btnEnableBorderColor) }}><button Style={`background-color:transparent`} id="selectCol" className="btn btn-default"></button></div>
                                        </div>
                                        {btnEnableBorderColor ? <BorderColorPicker Css={elementCss} setCss={setElementCss} setRowchange={setRowchange} setEnableBorderColor={setbtnEnableBorderColor} type='btn' /> : ''}
                                    </div> */}
                                </div>
                                {/* <span>Border Radius</span>
                                    <select className="form-control BorderRadiusSelect mb-3" id='btncolumnborderRadiusData' onClick={(e) => { setBtnBorderRadiusData(e.target.value) }}>
                                        <option value="0px">0px</option>
                                        <option value="5px">5px</option>
                                        <option value="10px">10px</option>
                                        <option value="15px">15px</option>
                                        <option value="20px">20px</option>
                                        <option value="25px">25px</option>
                                        <option value="50px">50px</option>
                                        <option value="75px">75px</option>
                                        <option value="100px">100px</option>
                                        <option value="125px">125px</option>
                                        <option value="150px">150px</option>
                                    </select>
                                    <span>Radius Edge</span>
                                    <select className="form-control BorderRadiusEdgeSelect mb-3" id='btncolumnborderRadiusType' onClick={(e) => { setBtnBorderRadiusType(e.target.value) }}>
                                        <option value="AllBorderEdges">All Edges</option>
                                        <option value="TopBorderEdges">Top Only Edges</option>
                                        <option value="BottomBorderEdges">Bottom Only Edges</option>
                                    </select> */}
                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="borderRadiusData-Sticky">Border Radius</InputLabel>
                                        <Select
                                            labelId="borderRadiusData"
                                            id="borderRadiusData"
                                            value={btnBorderRadiusData}
                                            name='borderRadiusData'
                                            onChange={(e) => { setBtnBorderRadiusData(e.target.value) }}
                                            label="borderRadiusData"
                                        >
                                            <MenuItem value={"0px"}>0px</MenuItem>
                                            <MenuItem value={"5px"}>5px</MenuItem>
                                            <MenuItem value={"10px"}>10px</MenuItem>
                                            <MenuItem value={"15px"}>15px</MenuItem>
                                            <MenuItem value={"20px"}>20px</MenuItem>
                                            <MenuItem value={"25px"}>25px</MenuItem>
                                            <MenuItem value={"50px"}>50px</MenuItem>
                                            <MenuItem value={"75px"}>75px</MenuItem>
                                            <MenuItem value={"100px"}>100px</MenuItem>
                                            <MenuItem value={"125px"}>125px</MenuItem>
                                            <MenuItem value={"150px"}>150px</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="borderRadiusType-Sticky">Radius Edge</InputLabel>
                                        <Select
                                            labelId="borderRadiusType"
                                            id="borderRadiusType"
                                            value={btnBorderRadiusType}
                                            name='borderRadiusType'
                                            onChange={(e) => { setBtnBorderRadiusType(e.target.value) }}
                                            label="borderRadiusType"
                                        >
                                            <MenuItem value={"AllBorderEdges"}>All Edges</MenuItem>
                                            <MenuItem value={"TopBorderEdges"}>Top Only Edges</MenuItem>
                                            <MenuItem value={"BottomBorderEdges"}>Bottom Only Edges</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </> : ''}
                    {elementType === 'button' || elementType === 'checkbox' ? <div className="SectionPropsWrapper mb-3 mt-3 pt-3" Style="padding-bottom: 30px">
                        {elementType === 'checkbox' ? <div class="container">
                            <custom>Line Height</custom>
                            <div class="input-group mb-3">
                                <input type="range" class="form-control-range propSlider addCssByDragingInput" min="0.7" max="20" step="0.1" name='lineHeight' value={elementCss.lineHeight} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                                <input class="input-group-text PropLabels propSliderLabel addCssByDragingValue" name='lineHeight' value={elementCss.lineHeight} onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} />
                            </div>
                        </div> : ''}
                        <div className="container">
                            {/* <div className="selectBlock">
                                <span>Text Transform</span>
                                <select className="form-control propSelect mb-3 " id='btnTextFormat' name='btnTextFormat' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} >
                                    <option value="none">Normal</option>
                                    <option value="uppercase">Uppercase</option>
                                    <option value="lowercase">Lowercase</option>
                                    <option value="capitalize">Capitalize</option>
                                </select>
                            </div> */}
                            <div className='d-flex'>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="btnTextFormat-Sticky">Text Transform</InputLabel>
                                    <Select
                                        labelId="btnTextFormat"
                                        id="btnTextFormat"
                                        value={elementCss.btnTextFormat}
                                        name='btnTextFormat'
                                        onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                        label="btnTextFormat"
                                    >
                                        <MenuItem value={"none"}>Normal</MenuItem>
                                        <MenuItem value={"uppercase"}>Uppercase</MenuItem>
                                        <MenuItem value={"lowercase"}>Lowercase</MenuItem>
                                        <MenuItem value={"capitalize"}>Capitalize</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {elementType === 'checkbox' ? <>
                                {/* <div class="selectBlock">
                                    <custom>Letter Spacing</custom>
                                    <select class="form-control propSelect mb-3 " id='ckBoxLetterSpacing' name='ckBoxLetterSpacing' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                        <option value="0px">Normal</option>
                                        <option value="1px">1px</option>
                                        <option value="2px">2px</option>
                                        <option value="3px">3px</option>
                                        <option value="-1px">-1px</option>
                                    </select>
                                </div> */}
                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="ckBoxLetterSpacing-Sticky">Letter Spacing</InputLabel>
                                        <Select
                                            labelId="ckBoxLetterSpacing"
                                            id="ckBoxLetterSpacing"
                                            value={elementCss.ckBoxLetterSpacing}
                                            name='ckBoxLetterSpacing'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="ckBoxLetterSpacing"
                                        >
                                            <MenuItem value={"0px"}>Normal</MenuItem>
                                            <MenuItem value={"1px"}>1px</MenuItem>
                                            <MenuItem value={"2px"}>2px</MenuItem>
                                            <MenuItem value={"3px"}>3px</MenuItem>
                                            <MenuItem value={"-1px"}>-1px</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {/* <div class="selectBlock">
                                    <custom>Text Shadow</custom>
                                    <select class="form-control propSelect mb-3 " id='ckBoxTextShadow' name='ckBoxTextShadow' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                        <option value="1" selected="">None</option>
                                        <option value="0.8">Light Fade</option>
                                        <option value="0.5">Half Fade</option>
                                        <option value="0.3">Heavy Fade</option>
                                    </select>
                                </div> */}
                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="ckBoxLetterSpacing-Sticky">Text Shadow</InputLabel>
                                        <Select
                                            labelId="ckBoxLetterSpacing"
                                            id="ckBoxLetterSpacing"
                                            value={elementCss.ckBoxLetterSpacing}
                                            name='ckBoxLetterSpacing'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="ckBoxLetterSpacing"
                                        >
                                            <MenuItem value={"1"}>None</MenuItem>
                                            <MenuItem value={"0.8"}>Light Fade</MenuItem>
                                            <MenuItem value={"0.5"}>Half Fade</MenuItem>
                                            <MenuItem value={"0.3"}>Heavy Fade</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </> : ''}
                        </div>{elementType === 'checkbox' ? '' : <><div className="container">
                            {/* <div className="selectBlock">
                                <span>Button Width</span>
                                <select className="form-control propSelect mb-3 " id='btnWidth' name='btnWidth' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} >
                                    <option value="auto">Fluid</option>
                                    <option value="100%">Full Width</option>
                                </select>
                            </div> */}
                            <div className='d-flex'>
                                <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="btnWidth-Sticky">Button Width</InputLabel>
                                    <Select
                                        labelId="btnWidth"
                                        id="btnWidth"
                                        value={elementCss.btnWidth}
                                        name='btnWidth'
                                        onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                        label="btnWidth"
                                    >
                                        <MenuItem value={"auto"}>Fluid</MenuItem>
                                        <MenuItem value={"100%"}>Full Width</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                            <div className="container">
                                {/* <div className="selectBlock">
                                    <span>Button Effects</span>
                                    <select className="form-control propSelect mb-3 " id='btnAnimation' name='btnAnimation' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} >
                                        <option value="none">No Effect</option>
                                        <option value="buttonPulseGlow">Glow (loop)</option>
                                        <option value="buttonRocking">Rocking (loop)</option>
                                        <option value="buttonBounce">Bounce (loop)</option>
                                        <option value="buttonElevate">Elevate (hover)</option>
                                        <option value="buttonWobble">Woobble (hover)</option>
                                    </select>
                                </div> */}
                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="btnAnimation-Sticky">Button Effect</InputLabel>
                                        <Select
                                            labelId="btnAnimation"
                                            id="btnAnimation"
                                            value={elementCss.btnAnimation}
                                            name='btnAnimation'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="btnAnimation"
                                        >
                                            <MenuItem value={"none"}>No Effect</MenuItem>
                                            <MenuItem value={"buttonPulseGlow"}>Glow (loop)</MenuItem>
                                            <MenuItem value={"buttonRocking"}>Rocking (loop)</MenuItem>
                                            <MenuItem value={"buttonBounce"}>Bounce (loop)</MenuItem>
                                            <MenuItem value={"buttonElevate"}>Elevate (hover)</MenuItem>
                                            <MenuItem value={"buttonWobble"}>Woobble (hover)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="container">
                                {/* <div className="selectBlock">
                                    <span>Button Shadow</span>
                                    <select className="form-control propSelect mb-3 " id='btnShadow' name='btnShadow' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }} >
                                        <option value="none">No Shadow</option>
                                        <option value="rgba(0, 0, 0, 0.05) 0px 1px 3px 0px">5% Drop Shadow</option>
                                        <option value="rgba(0, 0, 0, 0.1) 0px 1px 5px 0px">10% Drop Shadow</option>
                                        <option value="rgba(0, 0, 0, 0.2) 0px 1px 5px 0px">20% Drop Shadow</option>
                                        <option value="rgba(0, 0, 0, 0.3) 0px 2px 5px 2px">30% Drop Shadow</option>
                                        <option value="rgba(0, 0, 0, 0.4) 0px 2px 5px 2px">40% Drop Shadow</option>
                                        <option disabled="">──────────────────────</option>
                                        <option value="rgba(0, 0, 0, 0.05) 0px 1px 3px 0px inset">5% Inner Shadow</option>
                                        <option value="rgba(0, 0, 0, 0.1) 0px 1px 5px 0px inset">10% Inner Shadow</option>
                                        <option value="rgba(0, 0, 0, 0.2) 0px 1px 5px 0px inset">20% Inner Shadow</option>
                                        <option value="rgba(0, 0, 0, 0.3) 0px 2px 5px 2px inset">30% Inner Shadow</option>
                                        <option value="rgba(0, 0, 0, 0.4) 0px 2px 5px 2px inset">40% Inner Shadow</option>
                                    </select>
                                </div> */}
                                <div className='d-flex'>
                                    <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="btnShadow-Sticky">Button Shadow</InputLabel>
                                        <Select
                                            labelId="btnShadow"
                                            id="btnShadow"
                                            value={elementCss.btnShadow}
                                            name='btnShadow'
                                            onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                            label="btnShadow"
                                        >
                                            <MenuItem value={'none'}>No Shadow</MenuItem>
                                            <MenuItem value={'rgba(0, 0, 0, 0.05) 0px 1px 3px 0px'}>5% Drop Shadow</MenuItem>
                                            <MenuItem value={'rgba(0, 0, 0, 0.1) 0px 1px 5px 0px'}>10% Drop Shadow</MenuItem>
                                            <MenuItem value={'rgba(0, 0, 0, 0.2) 0px 1px 5px 0px'}>20% Drop Shadow</MenuItem>
                                            <MenuItem value={'rgba(0, 0, 0, 0.3) 0px 2px 5px 2px'}>30% Drop Shadow</MenuItem>
                                            <MenuItem value={'rgba(0, 0, 0, 0.4) 0px 2px 5px 2px'}>40% Drop Shadow</MenuItem>

                                            <MenuItem value={'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px inset'}>5% Inner Shadow</MenuItem>
                                            <MenuItem value={'rgba(0, 0, 0, 0.1) 0px 1px 5px 0px inset'}>10% Inner Shadow</MenuItem>
                                            <MenuItem value={'rgba(0, 0, 0, 0.2) 0px 1px 5px 0px inset'}>20% Inner Shadow</MenuItem>
                                            <MenuItem value={'rgba(0, 0, 0, 0.3) 0px 2px 5px 2px inset'}>30% Inner Shadow</MenuItem>
                                            <MenuItem value={'rgba(0, 0, 0, 0.4) 0px 2px 5px 2px inset'}>40% Inner Shadow</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </>} </div> : ''}
                    {elementType === 'inputfield' || elementType === 'textarea' || elementType === 'selectfield' ? <div id="inBetweenAdvanced" className="SectionPropsWrapper mb-3 mt-3 pt-3" Style="padding-bottom: 30px" ><div class="container">
                        <div class="selectBlock">
                            <span>Font Weight</span>
                            <select class="form-control propSelect mb-3 " id='formInputfontweight' name='formInputfontweight' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                <option selected="" value="100">Light</option>
                                <option selected="" value="400">Normal</option>
                                <option value="600">Bold</option>
                            </select>
                        </div>
                    </div><div class="container">
                            <div class="selectBlock">
                                <span>Text Align</span>
                                <select class="form-control propSelect mb-3 " id='formInputtextalign' name='formInputtextalign' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                    <option value="start">Left</option>
                                    <option value="center">Middle</option>
                                    <option value="end">Right</option>
                                </select>
                            </div>
                        </div><div class="container">
                            <div class="selectBlock">
                                <span>Corners</span>
                                <select class="form-control propSelect mb-3 " id='formInputborderradius' name='formInputborderradius' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                                    <option value="0px">Square</option>
                                    <option value="5px">5px</option>
                                    <option value="10px">10px</option>
                                    <option value="20px">20px</option>
                                    <option value="120px">120px</option>
                                </select>
                            </div>
                        </div></div> : ''}
                </div>
                <div className="container">
                    <div className="SectionPropsWrapper mb-3 mt-3 pt-3" Style="padding-bottom: 30px">
                        {/* <span>Animations (when visible)</span>
                        <select className="form-control enterAnimationsSelect mb-3" name='animationsection' id='animationcolumn' onClick={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}>
                            <option value="none">No Animation</option>
                            <option value="slideInFromRight">Slide in from right</option>
                            <option value="slideInFromLeft">Slide in from left</option>
                            <option value="slideInFromBottom">Slide in from bottom</option>
                            <option value="sweepToTop">Sweep to Top</option>
                            <option value="sweepToBottom">Sweep to Buttom</option>
                            <option value="sweepToLeft">Sweep to Left</option>
                            <option value="sweepToRight">Sweep to Right</option>
                            <option value="twisterInDown">Twister In Down</option>
                        </select> */}
                        <div className='d-flex'>
                            <FormControl size="small" sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="animationsection-Sticky">Animations (when visible)</InputLabel>
                                <Select
                                    labelId="animationsection"
                                    id="animationsection"
                                    value={elementCss.animationsection}
                                    name='animationsection'
                                    onChange={(e) => { setElementCss({ ...elementCss, [e.target.name]: e.target.value }) }}
                                    label="animationsection"
                                >
                                    <MenuItem value={"none"}>No Animation</MenuItem>
                                    <MenuItem value={"slideInFromRight"}>Slide in from right</MenuItem>
                                    <MenuItem value={"slideInFromLeft"}>Slide in from left</MenuItem>
                                    <MenuItem value={"slideInFromBottom"}>Slide in from bottom</MenuItem>
                                    <MenuItem value={"sweepToTop"}>Sweep to Top</MenuItem>
                                    <MenuItem value={"sweepToBottom"}>Sweep to Buttom</MenuItem>
                                    <MenuItem value={"sweepToLeft"}>Sweep to Left</MenuItem>
                                    <MenuItem value={"sweepToRight"}>Sweep to Right</MenuItem>
                                    <MenuItem value={"twisterInDown"}>Twister In Down</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {/* <div className="row m-0" Style="text-align:center;">
                        <span className="cssSelectorGroup col-md-8" id='rowIdElem'>
                            #{activeElement}
                        </span>
                        <span rowid={activeElement} className="copyIDSpan col-md-4" onClick={copySectionId} Style="display: flex; align-items: center; justify-content: center;"><i className="fas fa-copy" Style="cursor:pointer;" ></i></span>
                    </div> */}
                        <div class="container">
                            <div class="copy-text " id='rowCopytext'>
                                <span type="text" class="text" id='rowIdElem' Style='width:50%'>#{activeElement}</span>
                                <button onClick={copySectionId}><i class="fa fa-clone"></i></button>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    )
}

export default ElementAdvancedBar