import React, { useEffect } from 'react'
import { useState } from 'react';
import Countdown from 'react-countdown';

function CountDownElem({ size,
    id,
    addCustomStyle,
    index,
    columnIndex,
    rowIndex,
    sectionIndex,
    setSections,
    sections,
    element,
    activeElement,
    prevElement,
    preview,
    setElementType }) {
        const [clickDetect, setClickDetect] = useState(0)
        const handleEditorClick = (e) => {
          e.stopPropagation();
          setElementType('countdown')
          if (activeElement !== `elem_${id}`) {
            addCustomStyle(`elem_${id}`);
            setClickDetect(clickDetect + 1)
          }
        }
      
        useEffect(() => {
          addCustomStyle(`elem_${id}`);
        }, [])

    var dateArr = element.date.split('-')
    var timeArr=element.time.split(':')
    const countDownDate = new Date(Number(dateArr[0]), Number(dateArr[1]) - 1, Number(dateArr[2]), Number(timeArr[0]), Number(timeArr[1]),0).getTime()

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
            var days = Math.floor((countDownDate - new Date().getTime()) / (1000 * 60 * 60 * 24));
            var hours = Math.floor(((countDownDate - new Date().getTime()) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor(((countDownDate - new Date().getTime()) % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor(((countDownDate - new Date().getTime()) % (1000 * 60)) / 1000);
            document.getElementById(`countdownDemo_${id}`).innerHTML = "";
            // Output the result in an element with id="demo"
            if(isNaN(days)){
                document.getElementById(`${id}daysDiv`).innerHTML = 0
                document.getElementById(`${id}hoursDiv`).innerHTML = 0
                document.getElementById(`${id}minuteDiv`).innerHTML = 0
                document.getElementById(`${id}secondDiv`).innerHTML = 0
            }
            else{
                document.getElementById(`${id}daysDiv`).innerHTML = days
                document.getElementById(`${id}hoursDiv`).innerHTML = hours
                document.getElementById(`${id}minuteDiv`).innerHTML = minutes
                document.getElementById(`${id}secondDiv`).innerHTML = seconds
            }
            if((countDownDate - new Date().getTime())<0){
                clearInterval(interval)
                document.getElementById(`countdownDemo_${id}`).innerHTML = "EXPIRED";
                document.getElementById(`${id}daysDiv`).innerHTML = 0
                document.getElementById(`${id}hoursDiv`).innerHTML = 0
                document.getElementById(`${id}minuteDiv`).innerHTML = 0
                document.getElementById(`${id}secondDiv`).innerHTML = 0
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);


    useEffect(() => {
        document.getElementById(`${id}scriptTag`).innerHTML = `<script>var countDownDate = ${countDownDate}; var x = setInterval(function () { var now = new Date().getTime(); var distance = countDownDate - now; var days = Math.floor(distance / (1000 * 60 * 60 * 24)); var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)); var seconds = Math.floor((distance % (1000 * 60)) / 1000); document.getElementById("${id}daysDiv").innerHTML = days; document.getElementById("${id}hoursDiv").innerHTML = hours; document.getElementById("${id}minuteDiv").innerHTML = minutes; document.getElementById("${id}secondDiv").innerHTML = seconds; if (distance < 0) { clearInterval(x); document.getElementById("countdownDemo_${id}").innerHTML = "EXPIRED"; }; }, 1000);</script>`
    }, [countDownDate])

    return (
        <div onClick={handleEditorClick}>
            <div id={`${id}scriptTag`}  className='className="d-none"'>

            </div>
            <div id={`countdownDemo_${id}`}>

            </div>
            <span className={`d-flex flex-wrap  global${element.elementObject? element.elementObject.fonttype:''}Font`} Style={`color:${element.elementObject?element.elementObject.countdowntextcolor:'black'}; justify-content:${element.elementObject?element.elementObject.countdownposition:'center'}; font-family:${element.elementObject && element.elementObject.fonttype==="Custom" ? element.elementObject.fontstyle : ''}`}>
                <div className='countdownItems' Style={`background-color:${element.elementObject?element.elementObject.countdownbgcolor:'white'}`}>
                    <span id={`${id}daysDiv`} Style={`font-size:${element.elementObject ? element.elementObject.countDownDeskFontSize : '50'}px; display: block;`}></span>
                    <span class="countdownItemSubText" dfontsize="10px" mfontsize="10px" Style={`font-size:${element.elementObject ? element.elementObject.countDownTextDeskFontSize : '10'}px; display: block;`}>Days</span>
                </div>
                <div className='countdownItems' Style={`background-color:${element.elementObject?element.elementObject.countdownbgcolor:'white'}`}>
                    <span id={`${id}hoursDiv`} Style={`font-size:${element.elementObject ? element.elementObject.countDownDeskFontSize : '50'}px; display: block;`}></span>
                    <span class="countdownItemSubText" dfontsize="10px" mfontsize="10px" Style={`font-size:${element.elementObject ? element.elementObject.countDownTextDeskFontSize : '10'}px; display: block;`}>Hours</span>
                </div>
                <div className='countdownItems' Style={`background-color:${element.elementObject?element.elementObject.countdownbgcolor:'white'}`}>
                    <span id={`${id}minuteDiv`} Style={`font-size:${element.elementObject ? element.elementObject.countDownDeskFontSize : '50'}px; display: block;`}></span>
                    <span class="countdownItemSubText" dfontsize="10px" mfontsize="10px" Style={`font-size:${element.elementObject ? element.elementObject.countDownTextDeskFontSize : '10'}px; display: block;`}>Minute</span>
                </div>
                <div className='countdownItems' Style={`background-color:${element.elementObject?element.elementObject.countdownbgcolor:'white'}`}>
                    <span id={`${id}secondDiv`} Style={`font-size:${element.elementObject ? element.elementObject.countDownDeskFontSize : '50'}px; display: block;`}></span>
                    <span class="countdownItemSubText" dfontsize="10px" mfontsize="10px" Style={`font-size:${element.elementObject ? element.elementObject.countDownTextDeskFontSize : '10'}px; display: block;`}>Second</span>
                </div>
            </span>

        </div>
    )
}

export default CountDownElem