import React,{useState, useEffect} from 'react'
import parse from 'html-react-parser';

function JsHTML({ size,
  id,
  addCustomStyle,
  index,
  columnIndex,
  rowIndex,
  sectionIndex,
  setSections,
  sections,
  element,
  activeElement,
  prevElement,
  preview,
  setElementType }) {
  const [clickDetect, setClickDetect] = useState(0)
  const handleEditorClick = (e) => {
    e.stopPropagation();
    setElementType('customjshtml')
    if (activeElement !== `elem_${id}`) {
      addCustomStyle(`elem_${id}`);
      setClickDetect(clickDetect + 1)
    }
  }

  useEffect(() => {
    addCustomStyle(`elem_${id}`);
  }, [])

  var htmlObj;
  element.elementObject ? htmlObj = element.elementObject.customHtml : htmlObj = ''
  return (
    <div onClick={handleEditorClick}>{parse(htmlObj)}</div>
  )
}

export default JsHTML