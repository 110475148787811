import React, { useState, useEffect } from 'react'
import $ from "jquery";

function ImagePopUp(
  { size,
    id,
    addCustomStyle,
    index,
    columnIndex,
    rowIndex,
    sectionIndex,
    setSections,
    sections,
    element,
    activeElement,
    prevElement,
    preview,
    setElementType,
    rowId
  }
) {
  const [clickDetect, setClickDetect] = useState(0)
  const handleEditorClick = (e) => {
    e.stopPropagation();
    setElementType('imagepopup')
    if (activeElement !== `elem_${id}`) {
      addCustomStyle(`elem_${id}`);
      setClickDetect(clickDetect + 1)
    }
  }

  useEffect(() => {
    addCustomStyle(`elem_${id}`);
  }, [])

  // if(document.getElementById(`popup_elem_${id}`)){
  //   if(document.getElementById(`popup_elem_${id}`).classList.contains('show')){

  //     document.getElementById(rowId).classList.remove('rowIndex')
  //   }
  // }
  // else{
  //   document.getElementById(rowId).classList.add('rowIndex')
  // }
  $(function() {
    $('a[data-modal]').on('click', function() {
      $($(this).data('modal')).modal({
    fadeDuration: 250
  });
      return false;
    });
  });
  return (
    <>
      {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Launch demo modal
      </button>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              ...
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div> */}
      <div class="modal fade" id={`popup_elem_${id}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            {/* <div class="modal-body"> */}
            <img src={element.elementObject ? element.elementObject.imagepopupURL : 'https://images.unsplash.com/photo-1575936123452-b6…8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80'} Style='z-index:3035; max-height:80vh;' />
            {/* </div> */}
          </div>
        </div>
      </div>

      <div Style={`overflow:visible; text-align:${element.elementObject ? element.elementObject.textAlign : 'center'}`} id={id} onClick={handleEditorClick}>
        <div>
        </div>
        <img action="URL" data-bs-toggle="modal" data-bs-target={`#popup_elem_${id}`} className={`${element.elementObject ? element.elementObject.imageAnimation : ''}`} src={element.elementObject ? element.elementObject.imageURL : ''} alt={element.elementObject ? element.elementObject.imageAltText : ''} width={element.elementObject ? element.elementObject.imageWidth : ''} height={element.elementObject ? element.elementObject.imageHeight : ''} Style={`max-width: 100%; box-shadow: ${element.elementObject ? element.elementObject.imageBoxShadow : ''}; border: ${element.elementObject ? element.elementObject.imageBoxBorder : ''}; border-radius:${element.elementObject ? element.elementObject.imageRadius : ''}; opacity: ${element.elementObject ? element.elementObject.elementOpacity : ''}; filter: ${element.elementObject ? element.elementObject.imageEditing : ''};`} />
      </div>
    </>
  )
}

export default ImagePopUp