import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function SingleCarousel({ size,
  id,
  addCustomStyle,
  index,
  columnIndex,
  rowIndex,
  sectionIndex,
  setSections,
  sections,
  element,
  activeElement,
  prevElement,
  preview,
  setElementType,
  saving
}) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });
  const [clickDetect, setClickDetect] = useState(0)
  const handleEditorClick = (e) => {
    e.stopPropagation();
    setElementType('singlecarousel')
    if (activeElement !== `elem_${id}`) {
      addCustomStyle(`elem_${id}`);
      setClickDetect(clickDetect + 1)
    }
  }

  useEffect(() => {
    addCustomStyle(`elem_${id}`);
  }, [])
  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: true,
    autoplay: true,
    centerMode: true,
    swipeToSlide: true,
    centerPadding: '10px'
  };

  useEffect(() => {
    if (preview) {
      // setTimeout(() => {
      //   $('.slider-for').slick({
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     arrows: false,
      //     autoplay: true,
      //     fade: true,
      //     asNavFor: '.slider-nav'
      //   });
      //   $('.slider-nav').slick({
      //     slidesToShow: 3,
      //     slidesToScroll: 1,
      //     asNavFor: '.slider-for',
      //     arrows: false,
      //     autoplay: true,
      //     dots: true,
      //     focusOnSelect: true
      //   });
      // }, 200);
      //   document.getElementById(`${id}scriptTag`).innerHTML = `<script>
      //   $(document).ready(function () {
      //     $('.slider-for').slick({
      //       slidesToShow: 1,
      //       slidesToScroll: 1,
      //       arrows: false,
      //       fade: true,
      //       asNavFor: '.slider-nav'
      //     });
      //     $('.slider-nav').slick({
      //       slidesToShow: 3,
      //       slidesToScroll: 1,
      //       asNavFor: '.slider-for',
      //       arrows: false,
      //       dots: true,
      //       focusOnSelect: true
      //     });
      //   });
      // </script>`
    }
  }, [preview])



  return (
    <div >
      <div id={`${id}scriptTag`} className="d-none">

      </div>
      {saving ?
        <div class="slider-wrapper">
          <div class="slider slider-for" Style={`display:none`}>
            {element.elementObject ? element.elementObject.carouselImages.map((imageURL, index) =>
              <div className="slick-slide" key={index}>
                <img className="slick-slide-image" src={imageURL} />
              </div>
            ) : ''}
          </div>
          <div class="slider slider-nav">
            {element.elementObject ? element.elementObject.carouselImages.map((imageURL, index) =>
              <div className="slick-slide-div" key={index}>
                <img className="slick-slide-img" src={imageURL} />
              </div>
            ) : ''}
          </div>
        </div> : <div className="slider-wrapper" onClick={handleEditorClick}>
          {element.elementObject ? <div className="thumbnail-slider-wrap">
            <Slider
              {...settingsThumbs}
              asNavFor={nav1}
              ref={slider => (setSlider2(slider))}>

              {element.elementObject ? element.elementObject.carouselImages.map((imageURL, index) =>
                <div className="slick-slide" key={index}>
                  <img className="slick-slide-img" src={imageURL} />
                </div>
              ) : ''}

            </Slider>
          </div> : ''}

        </div>}
    </div>
  )
}

export default SingleCarousel