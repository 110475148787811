import React,{useEffect, useState} from 'react'

function Link({size,
  id,
  addCustomStyle,
  index,
  columnIndex,
  rowIndex,
  sectionIndex,
  setSections,
  sections,
  element,
  activeElement,
  prevElement,
  preview,
  setElementType}
  ) {
    const [clickDetect, setClickDetect] = useState(0)
    const handleEditorClick = (e) => {
      e.stopPropagation();
      setElementType('link')
      if (activeElement !== `elem_${id}`) {
        addCustomStyle(`elem_${id}`);
        setClickDetect(clickDetect + 1)
      }
    }
  
    useEffect(() => {
      addCustomStyle(`elem_${id}`);
    }, [])
    
  return (
    <div Style={`${element.elementTextStyle}; font-family:${element.elementObject && element.elementObject.fonttype==="Custom" ? element.elementObject.fontstyle : ''}`} className={` global${element.elementObject? element.elementObject.fonttype:''}Font `} id={id} onClick={handleEditorClick}>
      <a Style={`text-decoration:none;color:${element.elementObject ? element.elementObject.textColor : 'black'};`} href={element.elementObject ? element.elementObject.link : ''} target={(element.elementObject && element.elementObject.gotonewpage)?"_blank" : ''}>{element.elementObject ? element.elementObject.linktext : ''}</a>
    </div>
  )
}

export default Link