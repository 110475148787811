import React, { useState, useEffect } from 'react'
import * as FontAwesome from "react-icons/fa";
import parse from 'html-react-parser';

function ImageList({ size,
    id,
    addCustomStyle,
    index,
    columnIndex,
    rowIndex,
    sectionIndex,
    setSections,
    sections,
    element,
    activeElement,
    prevElement,
    preview,
    setElementType
}
) {
    const [clickDetect, setClickDetect] = useState(0)
    const [addicon, setAddIcon]=useState()
    const handleEditorClick = (e) => {
        e.stopPropagation();
        setElementType('imagelist')
        if (activeElement !== `elem_${id}`) {
            addCustomStyle(`elem_${id}`);
            setClickDetect(clickDetect + 1)
        }
    }

    useEffect(() => {
        addCustomStyle(`elem_${id}`);
    }, [])

    // useEffect(() => {
    //     if (element.icon) {
    //       if (element.icon !== "blank") {
    //         if (element.icon.includes("</svg>")) {
    //             setAddIcon(` <svg stroke="currentColor" fill="currentcolor" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto" xmlns="http://www.w3.org/2000/svg">${element.icon.slice(element.icon.indexOf("<path"), element.icon.indexOf("</svg>"))}</svg>')`)
    //         } else {
    //             setAddIcon(`<svg stroke="currentColor" fill="currentcolor" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto"  xmlns="http://www.w3.org/2000/svg">${element.icon}</svg>')`)
    //         }
    //       }
    //       else {
    //         setAddIcon(element.icon)
    //       }
    //     }
    //   }, [element.icon, element.iconWidth,element.iconColor])

    // var icon=element.elementObject?:''
    // var parse = function (str) {
    //     var parser = new DOMParser();
    //     var doc = parser.parseFromString(str, 'text/html');
    //     return doc.body;
    // };

    var icon = element.elementObject ? <div Style={`height:${element.elementObject ? element.elementObject.iconSize : ''}px; display:flex; color:${element.elementObject ? element.elementObject.iconColor : ''}`}>{element.elementObject && element.elementIcon === 'blank' ? <FontAwesome.FaAdn /> : element.elementIcon.includes('</svg>')?<svg stroke="currentColor" fill="currentcolor" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto" xmlns="http://www.w3.org/2000/svg">{parse(element.elementIcon.slice(element.elementIcon.indexOf("<path"), element.elementIcon.indexOf("</svg>")))}</svg>:<svg stroke="currentColor" fill="currentcolor" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto"  xmlns="http://www.w3.org/2000/svg">{parse(element.elementIcon)}</svg>}</div> : ''
    var textStyle = element.elementObject ? `font-weight:${element.elementObject.imagelistfontweight}; border-color:${element.elementObject.separatorColor}; font-family:${element.elementObject && element.elementObject.fonttype === "Custom" ? element.elementObject.fontstyle : ''}; text-align: ${element.elementObject ? element.elementObject.textAlign : 'left'} ` : ''
    return (
        <div id={id} onClick={handleEditorClick}>
            <div className="imageListHolder" mediatype="icon" currenticon="fa-solid fa-0">
                {element.elementObject ? element.elementObject.imageList.map((element) => {
                    return <><div className="imageListItem" id="imageListItem0977a" >
                        <div className="ili-image" Style="font-size: 16px;margin-right: 5px;">
                            {icon}
                        </div>
                            <p Style={textStyle} className={` global${element.elementObject ? element.elementObject.fonttype : ''}Font `}>{element}</p>
                    </div>
                    </>
                }) : ''}
            </div>
        </div>
    )
}

export default ImageList