import React, { memo, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from '../ItemTypes';

import Editor from "@monaco-editor/react";


import BulletList from './ElementComponentsCreated/TextComponents/BulletList';
import Heading from './ElementComponentsCreated/TextComponents/Heading'
import Paragraph from './ElementComponentsCreated/TextComponents/Paragraph';
import SubHeading from './ElementComponentsCreated/TextComponents/SubHeading';
import ImageList from './ElementComponentsCreated/TextComponents/ImageList';
import Icon from './ElementComponentsCreated/TextComponents/Icon';

import Video from './ElementComponentsCreated/MediaComponents/Video';
import Image from './ElementComponentsCreated/MediaComponents/Image';
import ImagePopUp from './ElementComponentsCreated/MediaComponents/ImagePopUp';
import VideoPopUp from './ElementComponentsCreated/MediaComponents/VideoPopUp';
import ImageFeature from './ElementComponentsCreated/MediaComponents/ImageFeature';
import SliderShowcase from './ElementComponentsCreated/MediaComponents/SliderShowcase';
import Button from './ElementComponentsCreated/ButtonLinkComponents/Button';
import Link from './ElementComponentsCreated/ButtonLinkComponents/Link';
import SocialShare from './ElementComponentsCreated/ButtonLinkComponents/SocialShare';
import InputFields from './ElementComponentsCreated/Forms/InputFields';
import TextAreaField from './ElementComponentsCreated/Forms/TextAreaField';
import CheckBox from './ElementComponentsCreated/Forms/CheckBox';
import SelectField from './ElementComponentsCreated/Forms/SelectField';
import Survey from './ElementComponentsCreated/Forms/Survey';

import CountDownElem from './ElementComponentsCreated/CountdownComponents/CountDownElem';
import Minutetimer from './ElementComponentsCreated/CountdownComponents/Minutetimer';
import Daytimer from './ElementComponentsCreated/CountdownComponents/Daytimer';

import Divider from './ElementComponentsCreated/BlockComponents/Divider';
import ProgressBar from './ElementComponentsCreated/BlockComponents/ProgressBar';
import Testimonial from './ElementComponentsCreated/BlockComponents/Testimonial';
import Tabs from './ElementComponentsCreated/BlockComponents/Tabs';
import Carousel from './ElementComponentsCreated/BlockComponents/Carousel';
import SingleCarousel from './ElementComponentsCreated/BlockComponents/SingleCarousel';

import Map from './ElementComponentsCreated/CustomComponents/Map';
import JsHTML from './ElementComponentsCreated/CustomComponents/JsHTML';

import TabsCustom from './ElementComponentsCreated/BlockComponents/TabsCustom';


const Element = memo(function Element({
    element,
    index,
    size,
    id,
    columnId,
    moveCardElement,
    columnHandlerId,
    addCustomStyle,
    sections,
    setSections,
    columnIndex,
    rowIndex,
    sectionIndex,
    activeElement,
    prevElement,
    section,
    row,
    column,
    setRowchange,
    rowchange,
    elementIdCount,
    setElementIdCount,
    preview,
    setElementType,
    elementType,
    openElementbar,
    setEnableColumnResize,
    enableColumnResize,
    enableForm,
    setEnableForm,
    rowId,
    sectionIdCount,
    setSectionIdCount,
    setAddSectionIndex,
    setSidebarOpen,
    bgElem,
    setSideNav,
    setRowId,
    setEnabledrop,
    enableDrop,
    setRowCss,
    rowCss,
    setRowHeading,
    rowName,
    setRowName,
    rowIdTrack,
    setRowIdTrack,
    sectionIdTrack,
    rowBgElem,
    setColumnIdTrack,
    columnIdTrack,
    columnName,
    setColumnName,
    setColumnHeading,
    setColumnCss,
    columnCss,
    columnBgElem,
    detectRow,
    columnSize,
    setColumnSize,
    targetColumnSize,
    nextColumnSize,
    setTargetColumnSize,
    setNextColumnSize,
    setElementName,
    elementName,
    setElementHeading,
    setElementCss,
    elementCss,
    setAddRowIndex,
    setAddColumnIndex,
    setPrevElement,
    setActiveElement,
    rowStateId,
    setRowStateId,


    openFunnelData,
    enableEditSection,
    setEnableEditSection,
    cssAddingBarByClick,
    enableRowSection,
    setEnableRowSection,
    setEnableColumnBar,
    enableColumnBar,
    elementEditBar,
    setElementEditBar,
    sidebarOpen,

    tabsSection,
    enableTabs,
    setEnableTabs,
    setTabSection,
    saving,
    undoComponent,
    setUndoComponent,
    mobileView
}) {
    const [elementAddCss, setElementAddCss] = useState(`#${id}{

}`)


    var renderElement = '';
    if (element.name === "headline") {
        renderElement = <Heading id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} elementType={elementType} setRowchange={setRowchange} setEnableColumnResize={setEnableColumnResize} enableColumnResize={enableColumnResize}  />
    }
    else if (element.name === "subheadline") {
        renderElement = <SubHeading id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} elementType={elementType} setEnableColumnResize={setEnableColumnResize} setRowchange={setRowchange} />
    }
    else if (element.name === "paragraph") {
        renderElement = <Paragraph id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} elementType={elementType} setEnableColumnResize={setEnableColumnResize} setRowchange={setRowchange} />
    }
    else if (element.name === "bulletlist") {
        renderElement = <BulletList id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} elementType={elementType} setEnableColumnResize={setEnableColumnResize} setRowchange={setRowchange} />
    }
    else if (element.name === "imagelist") {
        renderElement = <ImageList id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} elementType={elementType} />
    }
    else if (element.name === "icon") {
        renderElement = <Icon id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'video') {
        renderElement = <Video id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'image') {
        renderElement = <Image id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'imagepopup') {
        renderElement = <ImagePopUp id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} rowId={rowId} />
    }
    else if (element.name === 'videopopup') {
        renderElement = <VideoPopUp id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'imagefeature') {
        renderElement = <ImageFeature id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} elementType={elementType} setRowchange={setRowchange} setEnableColumnResize={setEnableColumnResize}   />
    }
    else if (element.name === 'slidershowcase') {
        renderElement = <SliderShowcase id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'button') {
        renderElement = <Button id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'link') {
        renderElement = <Link id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'socialshare') {
        renderElement = <SocialShare id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'inputfield') {
        renderElement = <InputFields id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'textarea') {
        renderElement = <TextAreaField id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'checkbox') {
        renderElement = <CheckBox id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'selectfield') {
        renderElement = <SelectField id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'survey') {
        renderElement = <Survey id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />
    }
    else if (element.name === 'premadeforminput') {
        renderElement = <InputFields id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'premadeformtextarea') {
        renderElement = <TextAreaField id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'premadeformcheckbox') {
        renderElement = <CheckBox id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'premadeformbutton') {
        renderElement = <Button id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'countdown') {
        renderElement = <CountDownElem id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'minutetimer') {
        renderElement = <Minutetimer id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'daytimer') {
        renderElement = <Daytimer id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'divider') {
        renderElement = <Divider id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'progressbar') {
        renderElement = <ProgressBar id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'testimonial') {
        renderElement = <Testimonial id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'tabs') {
        renderElement = <Tabs id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} setRowchange={setRowchange} rowchange={rowchange} sectionIdCount={sectionIdCount}
            setSectionIdCount={setSectionIdCount}
            setAddSectionIndex={setAddSectionIndex}
            setSidebarOpen={setSidebarOpen}
            enableEditSection={enableEditSection}
            setEnableEditSection={setEnableEditSection}
            cssAddingBarByClick={cssAddingBarByClick}
            setSideNav={setSideNav} setRowId={setRowId}
            rowId={rowId} setEnabledrop={setEnabledrop}
            enableDrop={enableDrop}
            enableRowSection={enableRowSection}
            setEnableRowSection={setEnableRowSection}
            setEnableColumnBar={setEnableColumnBar}
            enableColumnBar={enableColumnBar}
            elementIdCount={elementIdCount}
            setElementIdCount={setElementIdCount}
            elementEditBar={elementEditBar}
            setElementEditBar={setElementEditBar}
            setAddRowIndex={setAddRowIndex}
            setAddColumnIndex={setAddColumnIndex}
            setColumnIdTrack={setColumnIdTrack}
            columnIdTrack={columnIdTrack}
            elementType={elementType}
            sidebarOpen={sidebarOpen}
            openFunnelData={openFunnelData}
            rowStateId={rowStateId}
            setRowStateId={setRowStateId}

            tabsSection={tabsSection}
            enableTabs={enableTabs}
            setEnableTabs={setEnableTabs}
            setTabSection={setTabSection} />

    }
    else if (element.name === 'customjshtml') {
        renderElement = <JsHTML id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'map') {
        renderElement = <Map id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'customtab') {
        renderElement = <TabsCustom id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} />

    }
    else if (element.name === 'carousel') {
        renderElement = <Carousel id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} saving={saving}/>

    }
    else if (element.name === 'singlecarousel') {
        renderElement = <SingleCarousel id={id} mobileView={mobileView} size={size} element={element} addCustomStyle={addCustomStyle} index={index} columnIndex={columnIndex} rowIndex={rowIndex} sectionIndex={sectionIndex} sections={sections} setSections={setSections} activeElement={activeElement} prevElement={prevElement} preview={preview} setElementType={setElementType} saving={saving}/>

    }
    if (elementType === 'premadeform') {
        setEnableForm(true)
    }

    useEffect(() => {
        document.getElementById(`elem_${id}`).onmouseenter = () => {
            if (preview === false) {
                document.getElementById(columnId).classList.remove("columnHover")
                document.getElementById(columnId).classList.remove("selectColumnStyle")
                document.getElementById(columnId + "ControlLabelsColumn").classList.add("disable")
                if (document.getElementById(columnId + "BtnAddRowColumn") || document.getElementById(id + "PlusIconColumn")) {
                    document.getElementById(columnId + "BtnAddRowColumn").classList.add("disable")
                    document.getElementById(columnId + "PlusIconColumn").classList.remove("disable")
                }
                document.getElementById(id + "PlusElmButton").classList.remove("disable")
                document.getElementById(id + "ElmControlLabels").classList.remove("disable")
                document.getElementById(columnId + "typeNameColumn").classList.remove("typeNameHover")
                document.getElementById(columnId + "typeNameColumn").classList.add("disable")
                document.getElementById(id + "typeNameElement").classList.add("typeNameHover")
                document.getElementById(id + "typeNameElement").classList.remove("disable")
            }
        }
        document.getElementById(`elem_${id}`).onmouseleave = () => {
            if (preview === false) {
                document.getElementById(columnId).classList.add("columnHover")
                document.getElementById(columnId + "typeNameColumn").classList.remove("disable")
                // document.getElementById(columnId).classList.add("selectColumnStyle")
                document.getElementById(columnId + "ControlLabelsColumn").classList.remove("disable")
                if (document.getElementById(columnId + "BtnAddRowColumn") || document.getElementById(id + "PlusIconColumn")) {
                    document.getElementById(columnId + "BtnAddRowColumn").classList.remove("disable")
                    document.getElementById(columnId + "PlusIconColumn").classList.add("disable")
                }
                document.getElementById(id + "PlusElmButton").classList.add("disable")
                document.getElementById(id + "ElmControlLabels").classList.add("disable")
                document.getElementById(columnId + "typeNameColumn").classList.add("typeNameHover")
                document.getElementById(columnId + "typeNameColumn").classList.remove("disable")
                document.getElementById(id + "typeNameElement").classList.remove("typeNameHover")
                document.getElementById(id + "typeNameElement").classList.add("disable")
            }
        }
    }, [rowchange, sections, section, row, column, element, preview])

    const elemRef = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.ELEMENTUPDOWN,
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!elemRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = elemRef.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            moveCardElement(item, monitor, columnHandlerId)
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({

        type: ItemTypes.ELEMENTUPDOWN,
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1

    const [elemDragState, setElemDragState] = useState(true)

    useEffect(() => {
        if (isDragging === false) {
            setElemDragState(false)
        }
        else {
            setElemDragState(true)
        }
    }, [isDragging])


    useEffect(() => {
        setTimeout(() => {
            document.getElementById(`${element.id}`).addEventListener('mouseover', () => {
                setEnableColumnResize(true)
                setElemDragState(false)
            })
            document.getElementById(`${element.id}`).addEventListener('mouseout', () => {
                setEnableColumnResize(false)
                setElemDragState(true)
            })
        }, 100);
    }, [])

    if (enableColumnResize === false && preview == false) {
        drag(drop(elemRef));
    }
    else {
        drag()
    }

    // Move up Element
    function moveUpElement() {
        var movedData = sections;
        var sectionIndex;
        var rowIndex;
        var columnIndex;
        var elementIndex;
        sectionIndex = movedData.indexOf(section)
        rowIndex = movedData[sectionIndex].RowComponent.indexOf(row)
        columnIndex = movedData[sectionIndex].RowComponent[rowIndex].RowContainer.indexOf(column)
        movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.forEach((element) => {
            if (element.id === id) {
                elementIndex = movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.indexOf(element)
                if (elementIndex > 0) {
                    movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer[elementIndex] = movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.splice(elementIndex - 1, 1, movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer[elementIndex])[0]
                    setSections(movedData)
                    setRowchange(movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer)
                }
            }

        });
        
        var componentData = undoComponent
        componentData.push({ "arrayData": JSON.parse(JSON.stringify(movedData)), "id": id})
        setUndoComponent(componentData)
    }

    // Move Down Element
    function moveDownElement() {
        var movedData = sections;
        var sectionIndex;
        var rowIndex;
        var columnIndex;
        var elementIndex;
        var isSetting = true
        sectionIndex = movedData.indexOf(section)
        rowIndex = movedData[sectionIndex].RowComponent.indexOf(row)
        columnIndex = movedData[sectionIndex].RowComponent[rowIndex].RowContainer.indexOf(column)
        movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.forEach((element) => {
            if (element.id === id && isSetting === true) {
                elementIndex = movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.indexOf(element)
                if (elementIndex < movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.length - 1) {
                    movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer[elementIndex] = movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.splice(elementIndex + 1, 1, movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer[elementIndex])[0]
                    setSections(movedData)
                    setRowchange(movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer)
                    isSetting = false
                }
            }

        });
        var componentData = undoComponent
        componentData.push({ "arrayData": JSON.parse(JSON.stringify(movedData)), "id": id})
        setUndoComponent(componentData)
        isSetting = true
    }

    // Delete Element
    function deleteElement() {
        var movedData = sections;
        var sectionIndex;
        var rowIndex;
        var columnIndex;
        var elementIndex;
        sectionIndex = movedData.indexOf(section)
        rowIndex = movedData[sectionIndex].RowComponent.indexOf(row)
        columnIndex = movedData[sectionIndex].RowComponent[rowIndex].RowContainer.indexOf(column)
        movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.forEach((element) => {
            if (element.id === id) {
                elementIndex = movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.indexOf(element)
                movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.splice(elementIndex, 1)
                setSections(movedData)
                setRowchange(movedData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer)
            }

        });
        var componentData = undoComponent
        componentData.push({ "arrayData": JSON.parse(JSON.stringify(movedData)), "id": id})
        setUndoComponent(componentData)
    }

    // Copy Element 
    function copyElement() {
        var sectionsData = sections;
        var sectionIndex;
        var rowIndex;
        var columnIndex;
        var elementIndex;
        sectionIndex = sectionsData.indexOf(section)
        rowIndex = sectionsData[sectionIndex].RowComponent.indexOf(row)
        columnIndex = sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer.indexOf(column)
        sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.forEach((element) => {
            if (element.id === id) {
                elementIndex = sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.indexOf(element)
            }

        });
        var columnId = column.id
        var elementData = JSON.parse(JSON.stringify(sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer[elementIndex]));
        elementData.id = columnId + "elem" + elementIdCount + 1
        sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer.splice(elementIndex + 1, 0, elementData)
        console.log(sectionsData)
        // setSections(sectionsData)
        var componentData = undoComponent
        componentData.push({ "arrayData": JSON.parse(JSON.stringify(sectionsData)), "id": id})
        setUndoComponent(componentData)
        setElementIdCount(elementIdCount + 2)
        setRowchange(sectionsData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer)
    }
    const [clickDetect, setClickDetect] = useState(0)
    const handleEditorClick = (e) => {
        e.stopPropagation();
        setElementType(element.name)
        if (activeElement !== `elem_${id}`) {
            addCustomStyle(`elem_${id}`);
            setClickDetect(clickDetect + 1)
        }
    }

    useEffect(() => {
        addCustomStyle(`elem_${id}`);
    }, [])

    function handleEditorChange(value, event) {
        setElementAddCss(value)
    }

    function saveSectionAddCss() {
        document.getElementById(`dust${id}Style`).innerHTML = `<style>${elementAddCss}</style>`
    }

    return (
        <div ref={elemRef} Style={`${element.elementStyle};${element.elementObject ? element.elementObject.videoThemeCss : ''}; font-family:${element.elementObject ? element.elementObject.fontstyle : ''}`} className={`${preview ? '' : 'elementActive'} ${id}`} id={`elem_${id}`} handlerid={handlerId} onClick={handleEditorClick} >
            {renderElement}
            <div id={`elem_${id}Style`} Style='display:none'>
                <style>

                </style>
            </div>
            {preview ? '' : <div class="modal fade" id={`${id}CssEditorModalElement`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-body">
                            <span Style="font-size: 20px;">
                                <i class="fas fa-code"></i>
                                <custom Style="font-size: 20px;">&nbsp; Custom CSS</custom>
                            </span>
                            <div class="mt-3 cssEditorHolder" Style='text-align:left'>
                                <Editor
                                    height="50vh"
                                    theme="vs-dark"
                                    defaultLanguage="css"
                                    defaultValue={elementAddCss}
                                    onChange={handleEditorChange}
                                />
                            </div>
                        </div>
                        <div class="modal-footer" Style="display:flex; justify-content: center;">
                            <div>
                                <p Style="color:red;"><em><u>Caution!</u></em> Global selectors like <b><i>html</i></b> or <b><i>button</i></b> will result in the entire page being affected.</p>
                                <p Style="color:#ff6a00;"><em><u>Tip:</u></em> If your styling won't work, try adding a <i>!important</i> flag after it</p>
                            </div>
                            <div>
                                <button type="button" class="btn btn-success saveCustomCss" Style="width: 100px;" onClick={saveSectionAddCss}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {preview ? '' : <button clicked="false"  className='PlusElmButton disable' id={`${id}PlusElmButton`} Style="z-index: 99; padding: 4px 5px 5px; color: white; position: absolute; border-radius: 100px; background-color: #f807fc; height: 22px; width: 22px; left: 50%; top: calc(100% - 10px); border: none; outline: none; font-size: 15px; ;" onClick={() => { openElementbar(columnId) }}><span Style="display:flex"><i className="fas fa-plus"></i></span></button>}
            {preview ? '' : <>
                <div className='typeNameElement disable' id={id + "typeNameElement"} >Element</div>
                <div className="ElmControlLabels disable" id={`${id}ElmControlLabels`} Style="display: flex; z-index: 10;">
                    <div className="grow MoveElement ms-2 ui-draggable-handle" data-toggle="tooltip" title="" data-original-title="Drag"><i className="fa fa-arrows-alt"></i></div>
                    <div className="grow MoveElmUp ms-2" data-toggle="tooltip" title="" data-original-title="Move Up" onClick={moveUpElement}><i className="fa fa-arrow-up"></i></div>
                    <div className="grow MoveElmDown ms-2" data-toggle="tooltip" title="" data-original-title="Move Down" onClick={moveDownElement}><i className="fa fa-arrow-down"></i></div>
                    <div className="grow CopyElm ms-2" data-toggle="tooltip" title="" data-original-title="Clone" onClick={copyElement}><i className="fa fa-copy"></i></div>
                    <div className="grow openCustomCss ms-2" data-toggle="tooltip" title="" data-original-title="Open custom css" data-bs-toggle="modal" data-bs-target={`#${id}CssEditorModalElement`}><i className="fab fa-css3-alt"></i></div>
                    <div className="grow DeleteElm ms-2" data-toggle="tooltip" title="" data-original-title="Delete" onClick={deleteElement}><i className="fa fa-trash"></i></div>
                </div>
            </>}
        </div>
    )
})

export default Element;