import React,{useState, useEffect} from 'react'
import parse from 'html-react-parser';

function TabsCustom({ size,
    id,
    addCustomStyle,
    index,
    columnIndex,
    rowIndex,
    sectionIndex,
    setSections,
    sections,
    element,
    activeElement,
    prevElement,
    preview,
    setElementType }) {
        const [clickDetect, setClickDetect] = useState(0)
    const handleEditorClick = (e) => {
      e.stopPropagation();
      setElementType('customtab')
      if (activeElement !== `elem_${id}`) {
        addCustomStyle(`elem_${id}`);
        setClickDetect(clickDetect + 1)
      }
    }
  
    useEffect(() => {
      addCustomStyle(`elem_${id}`);
    }, [])

    return (
        <div onClick={handleEditorClick}>
            <nav>
                <div class="nav nav-tabs globalHeaderFont" id="custom-nav-tabs" role="tablist" Style="justify-content: start; height: 53px">
                    {element.elementObject ? element.elementObject.customTabs.map((elem,index) => {
                        return <button Style="border:none; outline: none; background:none; color:#4a5568; padding-right: 20px; padding-left: 0px;" class={`nav-link ${index===0?' active':''}`} data-bs-toggle="tab" data-bs-target={`#tabSection_${index}`} type="button" role="tab"  >{elem.tabName}</button>
                    }) : ''}
                </div>
            </nav>
            <div class="tab-content" id="myTabContent">
                {element.elementObject ? element.elementObject.customTabs.map((elem,index) => {
                    return <div class={`tab-pane fade ${index===0?' active':''}`} id={`tabSection_${index}`} role="tabpanel" tabindex="0" Style='min-height:100px'>{parse(elem.tabHTML)}</div>
                }) : ''}
            </div>
            
        </div>
    )
}

export default TabsCustom