import './App.css';
import { useState} from 'react';
import './Main.css'
import Home from './NavigationComponents/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import AboutUs from './NavigationComponents/AboutUs';
// import ContactUs from './NavigationComponents/ContactUs';

function App() {
const baseUrl=process.env.REACT_APP_BASE_URL

  return (
    <div className="App">
      <BrowserRouter >
        <Routes >
          <Route path='/:funnelid' element={<Home baseUrl={baseUrl} />} />
          <Route path='/' element={<Home baseUrl={baseUrl} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
