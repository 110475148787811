import React, { useState,useEffect } from 'react'

function SliderShowcase(
  { size,
    id,
    addCustomStyle,
    index,
    columnIndex,
    rowIndex,
    sectionIndex,
    setSections,
    sections,
    element,
    activeElement,
    prevElement,
    preview,
    setElementType }
) {
  const [clickDetect, setClickDetect] = useState(0)
  const handleEditorClick = (e) => {
    e.stopPropagation();
    setElementType('slidershowcase')
    if (activeElement !== `elem_${id}`) {
      addCustomStyle(`elem_${id}`);
      setClickDetect(clickDetect + 1)
    }
  }
  useEffect(() => {
    addCustomStyle(`elem_${id}`);
  }, [])
  
  return (
    <div  id={id} onClick={handleEditorClick}>
      <marquee behavior="alternate" direction={element.elementObject ? element.elementObject.sliderDirection : ''} Style="pointer-events:none;">
        {element.elementObject ? element.elementObject.sliderShowCase.map((elem) => {
          return <a class="marqueeItem" name="Image 1" href={elem.sliderRedirect==="URL"?elem.sliderRedirectURL:''}>
            <img class="zoom" src={elem.url} Style={`margin-right:20px; border-radius:5px; height:${elem.height}px; width:${elem.width}px;`} alt={elem.altText} />
          </a>
        }) : ''}
      </marquee>
    </div>
  )
}

export default SliderShowcase