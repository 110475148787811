import React, { useEffect, useState } from "react";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

function ImageFeaturePara({
  size,
  id,
  addCustomStyle,
  index,
  columnIndex,
  rowIndex,
  sectionIndex,
  setSections,
  sections,
  element,
  activeElement,
  prevElement,
  preview,
  setElementType,
  elementType,
  detectActiveImageFeature,
  setRowchange,
  handleEditorClickMain,
  mobileView
}) {
  const [clickDetect, setClickDetect] = useState(0)
  // const [iconData, setIconData] = useState()
  const [iconStyle, setIconStyle] = useState()
  const module = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
    ],
  }
  const { quill, quillRef } = useQuill({ module });
  const [HTMLValue, setHTMLValue] = useState(element.paraValue);

  React.useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setHTMLValue(quillRef.current.firstChild.innerHTML)
      });
    }
  }, [quill]);

  const handleEditorClick = (e) => {
    e.stopPropagation();
    // setElementType('paragraph')
    if (activeElement !== `imgFeaturePara${id}`) {
      if (activeElement !== `elem_${id}`) {
        setElementType('imagefeature')
        addCustomStyle(`elem_${id}`);
        setClickDetect(clickDetect + 1)
      }
      detectActiveImageFeature(`imgFeaturePara${id}`)
    }
  }

  useEffect(() => {
    detectActiveImageFeature(`imgFeaturePara${id}`);
  }, [])

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`imgFeaturePara${id}`).children[1].children[0].innerHTML = element.paraValue
      if (activeElement) {
        var activId = activeElement
        var prevId = prevElement
        document.getElementById(`${activId}`).children[0].style.display = "block"
        document.getElementById(`${prevId}`).children[0].style.display = "none"
      }
    }, 140)
  }, [prevElement, activeElement, clickDetect])

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`imgFeaturePara${id}`).children[0].style.display = "none"
      document.getElementById(`imgFeaturePara${id}`).children[1].children[0].style.padding = "0px"
    }, 50);
  }, [])

  // useEffect(() => {
  //   var data = element.icon === 'blank' ? '' : document.getElementById(`headingIcon_${id}`).innerHTML
  //   setIconData(data)
  // }, [element.icon])

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`imgFeaturePara${id}`).children[1].children[0].children[0].style = element.imageFeatureTextStyle
    }, 120);
  }, [element.imageFeatureTextStyle, prevElement, activeElement])

  useEffect(() => {
    setTimeout(() => {
      var toolBarHeight = document.getElementById(`imgFeaturePara${id}`).children[0].scrollHeight + 64
      document.getElementById(`imgFeaturePara${id}`).children[0].style.bottom = `-${toolBarHeight}px`
    }, 250);
  }, [size, prevElement, activeElement])

  useEffect(() => {
    var sectionData = sections
    sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer[index].paraValue = HTMLValue
    setSections(sectionData)
  }, [HTMLValue])

  useEffect(() => {
    if (element.icon) {
      if (element.icon !== "blank") {
        if (element.icon.includes("</svg>")) {
          setIconStyle(`#${id}>.ql-container>.ql-editor>p::before {
          content: url('data:image/svg+xml, <svg stroke="currentColor" fill="${element.iconColor?element.iconColor:'currentcolor'}" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto" xmlns="http://www.w3.org/2000/svg">${element.icon.slice(element.icon.indexOf("<path"), element.icon.indexOf("</svg>"))}</svg>');
          display: inline-block;
          width: ${element.icon ? element.iconWidth : ''}px;
        };
        `)
        } else {
          setIconStyle(`#${id}>.ql-container>.ql-editor>p::before {
          content: url('data:image/svg+xml, <svg stroke="currentColor" fill="${element.iconColor?element.iconColor:'currentcolor'}" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto"  xmlns="http://www.w3.org/2000/svg">${element.icon}</svg>');
          display: inline-block;
          width: ${element.icon ? element.iconWidth : ''}px;
        };
        `)
        }
      }
      else {
        setIconStyle(`#${id}>.ql-container>.ql-editor>p::before {
        content: url('data:image/svg+xml, ${element.icon}');
        display: inline-block;
        width: 0px;
      };
      `)
      }
    }
  }, [element.icon, element.iconWidth,element.iconColor])

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(id).children[1].children[0].style.textAlign = element.elementObject.textAlign
    }, 200);
  }, [element.elementTextStyle])

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`imgFeaturePara${id}`).children[0].style.display = 'none'
    }, 150);
  }, [])

  // useEffect(() => {
  //   // if (document.getElementById(`imgFeaturePara${id}`).children[1].children[1]) {
  //   //   document.getElementById(`imgFeaturePara${id}`).children[1].children[1].children[0].children[0].style.fontSize =`${element.imageFeatureCssObj.textDesktopFontSize}px`
  //   //   document.getElementById(`imgFeaturePara${id}`).children[1].children[1].children[0].children[0].style.color = `${element.imageFeatureCssObj.imgFeatureTextColor}`
  //   // }
  //   // setRowchange(element.imageFeatureCssObj)
  // }, [element.imageFeatureCssObj, element.imageFeatureCssObj.imgFeatureTextColor, element.imageFeatureCssObj.imgFeatureHeaderColor])

  useEffect(() => {
    if (preview === true) {
      document.getElementById(`imgFeaturePara${id}`).children[0].style.display = `none`
      var editorEditable = document.getElementById(id).children[1].children[0]
      editorEditable.contentEditable = 'false'
    }
    if (preview === false) {
      var editorEditable = document.getElementById(id).children[1].children[0]
      editorEditable.contentEditable = 'true'
    }
  }, [preview, clickDetect])
  useEffect(() => {
    document.getElementById(`imageFeaturePara_${id}responsiveStyle`).innerHTML = `<style>@media only screen and (max-width: 768px) { #${"imageFeaturePara_" + id + "_editor"}{${element.elementObject ? `font-size:${element.imageFeatureCssObj.textMobileFontSize} !important` : ''}} }</style>`
  }, [preview])
  return (
    <>
      <div Style='display:none'>
        <style >
          {iconStyle}
        </style>
      </div>
      <div id={`imageFeaturePara_${id}responsiveStyle`} Style='display:none'>
        <style>

        </style>
      </div>
      {/* <div id={`headingIcon_${id}`} Style='display:none'>{element.icon}</div> */}
      <div style={{ position: 'relative', top: '-10px' }} className={`paragraph imgFeaturePara  global${element.elementObject ? element.elementObject.fonttype : ''}Font`} id={`imgFeaturePara${id}`} onClick={handleEditorClick}>
        <div ref={quillRef} Style={`${element.imageFeatureTextStyle}; font-family:${element.elementObject && element.elementObject.fonttype === "Custom" ? element.elementObject.fontstyle : ''}; font-size:${mobileView ? element.imageFeatureCssObj.textMobileFontSize
          : element.imageFeatureCssObj.textDesktopFontSize}px; color:${element.imageFeatureCssObj.imgFeatureTextColor};`} id={`imageFeaturePara_${id}_editor`} />
      </div>
    </>
  )
}
export default ImageFeaturePara