import React, { useEffect, useState } from 'react'

function Button({ size,
  id,
  addCustomStyle,
  index,
  columnIndex,
  rowIndex,
  sectionIndex,
  setSections,
  sections,
  element,
  activeElement,
  prevElement,
  preview,
  setElementType,
  mobileView
 }) {
  const [clickDetect, setClickDetect] = useState(0)
  const handleEditorClick = (e) => {
    e.stopPropagation();
    setElementType('button')
    if (activeElement !== `elem_${id}`) {
      addCustomStyle(`elem_${id}`);
      setClickDetect(clickDetect + 1)
    }
  }

  useEffect(() => {
    addCustomStyle(`elem_${id}`);
  }, [])

  return (
    <div Style={`width:100%; ${element.elementTextStyle}; `} id={id} onClick={handleEditorClick}>
      {element.elementObject && element.elementObject.buttonAction === 'none' ?
        <button type="btn" action="popup" className={`noBorder allButton btn none ${element.elementObject ? element.elementObject.btnAnimation : ''} ${element.elementObject ? element.elementObject.btnThemeClass : ''} `} Style={`font-weight:normal; color:${element.elementObject ? element.elementObject.textColor : ''} ; padding:12px; background-color:${element.elementObject ? element.elementObject.btnBackgroundColor : ''}; letter-spacing:${element.elementObject ? element.elementObject.elementLetterSpacing : ''}; ${element.elementTextStyle}; border${element.elementObject ? element.elementObject.btnborder : ''}: ${element.elementObject ? element.elementObject.btnborderstyleCss.pixel : ''} ${element.elementObject ? element.elementObject.btnborderstyleCss.type : ''} ${element.elementObject ? element.elementObject.btnborderstyleCss.color : ''}; border-radius:${element.elementObject ? element.elementObject.btnborderRadius : ''}; text-align:center !important; width:${element.elementObject ? element.elementObject.btnWidth : ''}; box-shadow:${element.elementObject ? element.elementObject.btnShadow : ''}; text-transform:${element.elementObject ? element.elementObject.btnTextFormat : ''}; ${element.elementObject ? element.elementObject.btnThemeCss : ''};  `} redirect="https://www.google.com/">
          <div className={`BtnTextHolders d-flex justify-content-center align-items-center  global${element.elementObject ? element.elementObject.fonttype : ''}Font`} id="BtnTextHoldera2e6b" Style={`font-family:Montserrat; font-size:${element.elementObject ? element.elementObject.headerDesktopFontSizeBtn : ''}px; font-family:${element.elementObject && element.elementObject.fonttype === "Custom" ? element.elementObject.fontstyle : ''}`} dfontsize="20px" mfontsize="20px">
            <div Style='margin-right: 5px; display: flex;'>{element.elementObject ? element.elementObject.elementIcon === 'blank' ? '' : element.elementObject.elementIcon : ''}</div>
            {element.elementObject ? element.elementObject.btntext : ''}
          </div>
          <div className="BtnTextHolders" id="BtnTextHoldera2e6b" Style={`font-family:Montserrat; font-size:${element.elementObject ? element.elementObject.textDesktopFontSizeBtn : ''}px;`} dfontsize="20px" mfontsize="20px">{element.elementObject ? element.elementObject.btnsubtext : ''}</div>
        </button> : <a href={element.elementObject ? element.elementObject.buttonActionValue : ''} target='_blank'>
          <button type="btn" action="popup" className={`noBorder allButton btn none ${element.elementObject ? element.elementObject.btnAnimation : ''} ${element.elementObject ? element.elementObject.btnThemeClass : ''} `} Style={`font-weight:normal; color:${element.elementObject ? element.elementObject.textColor : ''} ; padding:12px; background-color:${element.elementObject ? element.elementObject.btnBackgroundColor : ''}; letter-spacing:${element.elementObject ? element.elementObject.elementLetterSpacing : ''}; ${element.elementTextStyle}; border${element.elementObject ? element.elementObject.btnborder : ''}: ${element.elementObject ? element.elementObject.btnborderstyleCss.pixel : ''} ${element.elementObject ? element.elementObject.btnborderstyleCss.type : ''} ${element.elementObject ? element.elementObject.btnborderstyleCss.color : ''}; border-radius:${element.elementObject ? element.elementObject.btnborderRadius : ''}; text-align:center !important; width:${element.elementObject ? element.elementObject.btnWidth : ''}; box-shadow:${element.elementObject ? element.elementObject.btnShadow : ''}; text-transform:${element.elementObject ? element.elementObject.btnTextFormat : ''}; ${element.elementObject ? element.elementObject.btnThemeCss : ''};  `} redirect="https://www.google.com/">
            <div className={`BtnTextHolders d-flex justify-content-center align-items-center  global${element.elementObject ? element.elementObject.fonttype : ''}Font`} id="BtnTextHoldera2e6b" Style={`font-family:Montserrat; font-size:${element.elementObject ? element.elementObject.headerDesktopFontSizeBtn : ''}px; font-family:${element.elementObject && element.elementObject.fonttype === "Custom" ? element.elementObject.fontstyle : ''}`} dfontsize="20px" mfontsize="20px">
              <div Style='margin-right: 5px; display: flex;'>{element.elementObject ? element.elementObject.elementIcon === 'blank' ? '' : element.elementObject.elementIcon : ''}</div>
              {element.elementObject ? element.elementObject.btntext : ''}
            </div>
            <div className="BtnTextHolders" id="BtnTextHoldera2e6b" Style={`font-family:Montserrat; font-size:${element.elementObject ? element.elementObject.textDesktopFontSizeBtn : ''}px;`} dfontsize="20px" mfontsize="20px">{element.elementObject ? element.elementObject.btnsubtext : ''}</div>
          </button>
        </a>}
    </div>
  )
}

export default Button