import React,{useState, useEffect} from 'react'

function ProgressBar({ size,
  id,
  addCustomStyle,
  index,
  columnIndex,
  rowIndex,
  sectionIndex,
  setSections,
  sections,
  element,
  activeElement,
  prevElement,
  preview,
  setElementType }) {
    const [clickDetect, setClickDetect] = useState(0)
    const handleEditorClick = (e) => {
      e.stopPropagation();
      setElementType('progressbar')
      if (activeElement !== `elem_${id}`) {
        addCustomStyle(`elem_${id}`);
        setClickDetect(clickDetect + 1)
      }
    }
  
    useEffect(() => {
      addCustomStyle(`elem_${id}`);
    }, [])
  return (
    <div Style={`text-align:${element.elementObject ? element.elementObject.divideralign : 'left'}; opacity:${element.elementObject ? element.elementObject.elementOpacity : 1}`} onClick={handleEditorClick}>
      <p class="m-0 ml-3 progressLabel">{element.elementObject ? element.elementObject.progresstext : 'Progressing...'}</p>
      <div class="progress" Style={`height: ${element.elementObject ? element.elementObject.progresssize : '25px'}; background-color:rgb(212, 215, 218);`}>
        <div class={`progress-bar ${element.elementObject && element.elementObject.stripeanimation ? 'progress-bar-animated progress-bar-striped' : ''} `} role="progressbar" Style={`width:${element.elementObject ? element.elementObject.progresswidth : '60%'} ; font-size: 18px; font-family:${element.elementObject && element.elementObject.fonttype==="Custom" ? element.elementObject.fontstyle : ''};`}></div>
      </div>
    </div>
  )
}

export default ProgressBar