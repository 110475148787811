import React, { useState, useEffect } from 'react'

function VideoPopUp(
  { size,
    id,
    addCustomStyle,
    index,
    columnIndex,
    rowIndex,
    sectionIndex,
    setSections,
    sections,
    element,
    activeElement,
    prevElement,
    preview,
    setElementType }
) {
  const [clickDetect, setClickDetect] = useState(0)
  const handleEditorClick = (e) => {
    e.stopPropagation();
    setElementType('videopopup')
    if (activeElement !== `elem_${id}`) {
      addCustomStyle(`elem_${id}`);
      setClickDetect(clickDetect + 1)
    }
  }

  useEffect(() => {
    addCustomStyle(`elem_${id}`);
  }, [])

  return (
    <div Style={`overflow:visible; text-align:${element.elementObject ? element.elementObject.textAlign : 'center'}`} id={id} onClick={handleEditorClick}>
      <div>
        <div class="modal fade" id={`popup_elem_${id}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl" Style='height:90%'>
            <div class="modal-content" Style='height:90%'>
              {/* <div class="modal-body"> */}
              {/* <img src={element.elementObject ? element.elementObject.imagepopupURL : ''} Style=''/> */}
              <iframe Style='z-index:3000; height:100%' src={`https://www.youtube.com/embed/${element.elementObject ? element.elementObject.videopopupEmbaded : ''}?autoplay=1&controls=1`}></iframe>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <img action="URL" data-bs-toggle="modal" data-bs-target={`#popup_elem_${id}`} className={`${element.elementObject ? element.elementObject.imageAnimation : ''}`} src={element.elementObject ? element.elementObject.imageURL : ''} alt={element.elementObject ? element.elementObject.imageAltText : ''} width={element.elementObject ? element.elementObject.imageWidth : ''} height={element.elementObject ? element.elementObject.imageHeight : ''} Style={`max-width: 100%; box-shadow: ${element.elementObject ? element.elementObject.imageBoxShadow : ''}; border: ${element.elementObject ? element.elementObject.imageBoxBorder : ''}; border-radius:${element.elementObject ? element.elementObject.imageRadius : ''}; opacity: ${element.elementObject ? element.elementObject.elementOpacity : ''}; filter: ${element.elementObject ? element.elementObject.imageEditing : ''};`} />
    </div>
  )
}

export default VideoPopUp