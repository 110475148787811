import React, { useEffect, useState, useContext } from "react";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import * as FontAwesome from "react-icons/fa";
import { HomeData } from "../../../../NavigationComponents/Home";

function Heading({
  size,
  id,
  addCustomStyle,
  index,
  columnIndex,
  rowIndex,
  sectionIndex,
  setSections,
  sections,
  element,
  activeElement,
  prevElement,
  preview,
  setElementType,
  elementType,
  mobileView,
  setRowchange,
}) {
  const { setEnableColumnResize, enableColumnResize } = useContext(HomeData)

  const [clickDetect, setClickDetect] = useState(0)
  // const [iconData, setIconData] = useState()
  const [iconStyle, setIconStyle] = useState()
  const module = {
    toolbar: [
      ['bold', 'italic', 'underline', 'color', 'background',]
    ],
  }

  const { quill, quillRef } = useQuill({ module });
  const [HTMLValue, setHTMLValue] = useState(element.value);

  React.useEffect((size, id) => {
    if (quill) {
      quill.on('text-change', () => {
        setHTMLValue(quillRef.current.firstChild.innerHTML)
      });
    }
  }, [quill]);

  const handleEditorClick = (e) => {
    e.stopPropagation();
    if (!preview) {
      setElementType('headline')
      if (activeElement !== `elem_${id}`) {
        addCustomStyle(`elem_${id}`);
        setClickDetect(clickDetect + 1)
      }
    }
  }

  useEffect(() => {
    addCustomStyle(`elem_${id}`);
  }, [])

  useEffect(() => {
    if (activeElement && prevElement) {
      var prevElemName;
      var activeElemName;
      sections.forEach(elem1 => {
        elem1.RowComponent.forEach((elem2) => {
          elem2.RowContainer.forEach((elem3) => {
            elem3.ColumnContainer.forEach((elem4) => {
              if (elem4.id === prevElement.slice(5)) {
                prevElemName = elem4.name
              }
              if (elem4.id === activeElement.slice(5)) {
                activeElemName = elem4.name
              }
            })
          })
        })
      });
    }
    setTimeout(() => {
      document.getElementById(id).children[1].children[0].innerHTML = element.value
      if (prevElement) {
        var activId = activeElement
        var prevId = prevElement
        if (prevElemName === '' || prevElemName === 'headline' || prevElemName === 'subheadline' || prevElemName === 'paragraph' || prevElemName === 'bulletlist') {
          document.getElementById(prevId.slice(5)).children[0].style.display = "none"
        }
        if (activeElemName === 'headline') {
          document.getElementById(activId.slice(5)).children[0].style.display = "block"
        }
      }
    }, 180);

  }, [prevElement, activeElement, clickDetect])




  useEffect(() => {
    setTimeout(() => {
      var toolBarHeight = document.getElementById(id).children[0].scrollHeight + 16
      document.getElementById(id).children[0].style.bottom = `-${toolBarHeight}px`
    }, 250);
  }, [size, prevElement, activeElement])

  useEffect(() => {
    if (preview === true) {
      document.getElementById(id).children[0].style.display = `none`
      var editorEditable = document.getElementById(id).children[1].children[0]
      editorEditable.contentEditable = 'false'
    }
    if (preview === false) {
      setTimeout(() => {
        var editorEditable = document.getElementById(id).children[1].children[0]
        editorEditable.contentEditable = 'true'
      }, 300);
    }
  }, [preview, clickDetect])


  useEffect(() => {
    var sectionData = sections
    sectionData[sectionIndex].RowComponent[rowIndex].RowContainer[columnIndex].ColumnContainer[index].value = HTMLValue
    setSections(sectionData)
  }, [HTMLValue])

  useEffect(() => {
    if (element.icon) {
      if (element.icon !== "blank") {
        if (element.icon.includes("</svg>")) {
          setIconStyle(`#${id}>.ql-container>.ql-editor>p::before {
          content: url('data:image/svg+xml, <svg stroke="currentColor" fill="${element.iconColor?element.iconColor:'currentcolor'}" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto" xmlns="http://www.w3.org/2000/svg">${element.icon.slice(element.icon.indexOf("<path"), element.icon.indexOf("</svg>"))}</svg>');
          display: inline-block;
          width: ${element.icon ? element.iconWidth : ''}px;
        };
        `)
        } else {
          setIconStyle(`#${id}>.ql-container>.ql-editor>p::before {
          content: url('data:image/svg+xml, <svg stroke="currentColor" fill="${element.iconColor?element.iconColor:'currentcolor'}" stroke-width="0" viewBox="0 0 512 512" height="auto" width="auto"  xmlns="http://www.w3.org/2000/svg">${element.icon}</svg>');
          display: inline-block;
          width: ${element.icon ? element.iconWidth : ''}px;
        };
        `)
        }
      }
      else {
        setIconStyle(`#${id}>.ql-container>.ql-editor>p::before {
        content: url('data:image/svg+xml, ${element.icon}');
        display: inline-block;
        width: 0px;
      };
      `)
      }
    }
  }, [element.icon, element.iconWidth,element.iconColor])



  useEffect(() => {
    setTimeout(() => {
      document.getElementById(id).children[1].children[0].style.textAlign = element.elementObject.textAlign
    }, 230);
  }, [element.elementTextStyle])

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(id).children[0].style.display = 'none'
    }, 200);
  }, [])

  useEffect(() => {
    document.getElementById(`headingIcon_${id}responsiveStyle`).innerHTML = `<style>@media only screen and (max-width: 768px) { #${"headingIcon_" + id + "_editor"}{${element.elementObject ? `font-size:${element.elementObject.mobileFontSize} !important` : ''}} }</style>`
  }, [preview])


  function mouseDown() {
    setEnableColumnResize(true)
  }
  function mouseUp() {
    setEnableColumnResize(false)
  }

  return (
    <>
      <div Style='display:none'>
        <style >
          {iconStyle}
        </style>
      </div>
      <div id={`headingIcon_${id}responsiveStyle`} Style='display:none'>
        <style>

        </style>
      </div>
      <div id={`headingIcon_${id}`} Style={'display:none'}>{element.icon}</div>
      <div className={`headingEditor global${element.elementObject ? element.elementObject.fonttype : ''}Font`} id={id} onClick={handleEditorClick} onMouseDown={mouseDown} onMouseUp={mouseUp}>
        <div ref={quillRef} Style={`${mobileView ? element.mobileElementTextStyle : element.elementTextStyle}; font-family:${element.elementObject && element.elementObject.fonttype === "Custom" ? element.elementObject.fontstyle : ''}`} id={`headingIcon_${id}_editor`} />
      </div>
    </>
  )
}

export default Heading