import React,{useState, useEffect} from 'react'

function SocialShare({size,
  id,
  addCustomStyle,
  index,
  columnIndex,
  rowIndex,
  sectionIndex,
  setSections,
  sections,
  element,
  activeElement,
  prevElement,
  preview,
  setElementType}) {
    const [clickDetect, setClickDetect] = useState(0)
    const handleEditorClick = (e) => {
      e.stopPropagation();
      setElementType('socialshare')
      if (activeElement !== `elem_${id}`) {
        addCustomStyle(`elem_${id}`);
        setClickDetect(clickDetect + 1)
      }
    }
  
    useEffect(() => {
      addCustomStyle(`elem_${id}`);
    }, [])

  return (
    <div Style='display: flex; justify-content: center;' id={id} onClick={handleEditorClick}>
      {(element.elementObject && element.elementObject.enableFacebook)? <a Style="display: flex;align-items: center; justify-content: center; width: 30%; background-color: #2d4373;" className="btn shareElement faceBookBtn disableATags" actiontype="URL" href={element.elementObject.facebookUrl} target="_blank" data-toggle="tooltip" title="" data-original-title="Share on Facebook"><i className=" pe-1 fab fa-facebook-square"></i>Facebook</a>:''}
      {(element.elementObject && element.elementObject.enableTwitter)? <a Style="display: flex;align-items: center; justify-content: center; width: 30%; background-color: #1DA1F2;" className="btn shareElement twitterBtn disableATags" actiontype="URL" href={element.elementObject.twitterUrl} target="_blank" data-toggle="tooltip" title="" data-original-title="Share on Twitter"><i className=" pe-1 fab fa-twitter"></i>Twitter</a>:''}
      {(element.elementObject && element.elementObject.enableLinkedin)? <a Style="display: flex;align-items: center; justify-content: center; width: 30%; background-color: #395d90;" className="btn shareElement linkedinBtn disableATags" actiontype="URL" href={element.elementObject.linkedinUrl} target="_blank" data-toggle="tooltip" title="" data-original-title="Share on LinkedIn"><i className=" pe-1 fab fa-linkedin"></i>LinkedIn</a>:''}
    </div>
  )
}

export default SocialShare